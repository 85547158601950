import InstructionStore from '../scenes/Instruction/store'
import CallbackStore from '../scenes/Callback/store'
import SignalStore from '../scenes/Signal/store'
import FocusStore from '../scenes/Focus/store'
import ChartMonitor from '../scenes/ChartMonitor/store'

export default class App {
  constructor(rootStore) {
    this.rootStore = rootStore
    this.instructionStore = new InstructionStore(this)
    this.callbackStore = new CallbackStore(this)
    this.signalStore = new SignalStore(this)
    this.focusStore = new FocusStore(this)
    this.chartMonitorStore = new ChartMonitor(this)
  }
}
