import { action, observable } from 'mobx'

export default class UserInsightStore {

  @observable userInsights = []
  @observable userAccount = {}

    constructor(appStore) {
    this.rootStore = appStore.rootStore
    this.log = this.rootStore.log
  }

  @action('UserInsight Store | getUserInsights')
  getUserInsights = async id => {
    try {
      const { api } = this.rootStore
      const result = await api.get('/v0/getUserInsights')
      const {
        data: { userInsights = []},
      } = result
      this.userInsights = userInsights
    } catch (e) {
      this.log.warn(`UserInsight Store | getUserInsights ${e.stack}`)
    }
  }

  @action('UserInsight Store | getUserAccount')
  getUserAccount = async (email) => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/tradier.getUserAccount`)
      const {
        data: { accountInfo = {}},
      } = result
      accountInfo.email = email
      this.userAccount = accountInfo
    } catch (e) {
      this.log.warn(`UserInsight Store | getUserAccount ${e.stack}`)
    }
  }

}
