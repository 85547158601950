import { action, observable } from 'mobx'

export default class SignalStore {

  @observable buySignals = []
  @observable sellSignals = []
  @observable averageReturn
  @observable best
  @observable worst

    constructor(appStore) {
    this.rootStore = appStore.rootStore
    this.log = this.rootStore.log
  }

  @action('Marketplace Store | getAllBuySignals')
  getAllBuySignals = async id => {
    try {
      const { api } = this.rootStore
      const result = await api.get('/v0/buySignals')
      const {
        data: { buySignals = [], averageReturn, best, worst },
      } = result
      this.buySignals = buySignals
      this.averageReturn = averageReturn
      this.best = best
      this.worst = worst
    } catch (e) {
      this.log.warn(`SmartFund Store | getAllBuySignals ${e.stack}`)
    }
  }

  @action('Marketplace Store | getAllBuySignals')
  getAllSellSignals = async id => {
    try {
      const { api } = this.rootStore
      const result = await api.get('/v0/sellSignals')
      const {
        data: { sellSignals = [], averageReturn, best, worst },
      } = result
      this.sellSignals = sellSignals
      this.averageReturn = averageReturn
      this.best = best
      this.worst = worst
    } catch (e) {
      this.log.warn(`SmartFund Store | getAllSellSignals ${e.stack}`)
    }
  }

}
