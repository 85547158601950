import React from 'react'
import { Modal } from 'react-bootstrap'
import blueLogo from '../../../assets/img/bob-logo-modal.png'
import CircularProgress from '@material-ui/core/CircularProgress'

export function Success(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} className="settings-modal" bsSize="small">
      <Modal.Header closeButton>
        <div className="modal-logo-container">
          <img src={blueLogo} width={75} className="modal-logo" alt="logo" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title>{props.title || 'Your settings have been updated'}</Modal.Title>
        <div className="row">
          <div className="col-sm-5"></div>
          <div className="col-sm-2" style={{ textAlign: 'center' }}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle
                className="resultImages path circle"
                fill="none"
                stroke="#73AF55"
                strokeWidth="6"
                strokeMiterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                className="resultImages path check"
                fill="none"
                stroke="#73AF55"
                strokeWidth="6"
                strokeLinecap="round"
                strokeMiterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
          </div>
          <div className="col-sm-4"></div>
        </div>
        <button className="modal-button" onClick={props.onHide}>
          OK
        </button>
        {props.hideLegal && (
          <p className="legal">
            {' '}
            This is legalese text that informs the user of their right to have their information
            securely stored and in what format. They also have the right to add, update or
            completely remove this info at any time.
          </p>
        )}
      </Modal.Body>
    </Modal>
  )
}

export function Failure(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} className={props.className} bsSize="small">
      <Modal.Header style={{ textAlign: 'center' }} closeButton>
        <div className="modal-logo-container">
          <img src={blueLogo} width={75} className="modal-logo" alt="logo" />
        </div>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>
        <Modal.Title>{props.title || 'Oops, looks like there was a problem.'}</Modal.Title>
        <div className="row">
          <div className="col-sm-5"></div>
          <div className="col-sm-2" style={{ textAlign: 'center' }}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle
                className="path circle"
                fill="none"
                stroke="#D06079"
                strokeWidth="6"
                strokeMiterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <line
                className="path line"
                fill="none"
                stroke="#D06079"
                strokeWidth="6"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="34.4"
                y1="37.9"
                x2="95.8"
                y2="92.3"
              />
              <line
                className="path line"
                fill="none"
                stroke="#D06079"
                strokeWidth="6"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="95.8"
                y1="38"
                x2="34.4"
                y2="92.2"
              />
            </svg>
          </div>
          <div className="col-sm-4"></div>
        </div>
        <button className="modal-button" onClick={props.onHide}>
          OK
        </button>
      </Modal.Body>
    </Modal>
  )
}

export function Confirm(props) {
  const { className } = props
  return (
    <Modal show={props.show} onHide={props.onHide} className={className} bsSize="small">
      <Modal.Header style={{ textAlign: 'center' }} closeButton>
        <div className="modal-logo-container">
          <img src={blueLogo} width={75} className="modal-logo" alt="logo" />
        </div>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>
        <Modal.Title>{props.title || 'Are you sure?'}</Modal.Title>
        {props.body || 'This change will take effect immediately.'}
        {props.buttons || (
          <button className="modal-button" onClick={props.onCommit}>
            {props.commitMsg || 'Yes, update now!'}
          </button>
        )}
      </Modal.Body>
    </Modal>
  )
}

export function Updating(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} className="settings-modal" bsSize="small">
      <Modal.Header style={{ textAlign: 'center' }} closeButton>
        <div className="modal-logo-container">
          <img src={blueLogo} width={75} className="modal-logo" alt="logo" />
        </div>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>
        <Modal.Title>{props.title || 'Updating ...'}</Modal.Title>
        <button className="modal-button" onClick={props.onCommit}>
          {props.commitMsg || 'Yes, update now!'}
        </button>
      </Modal.Body>
    </Modal>
  )
}

export function Loading(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} className="settings-modal" bsSize="small">
      <Modal.Header style={{ textAlign: 'center' }} closeButton>
        <div className="modal-logo-container">
          <img src={blueLogo} width={75} className="modal-logo" alt="logo" />
        </div>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>
        <Modal.Title>{props.message || 'One moment please'}</Modal.Title>
        <br />
        <CircularProgress />
      </Modal.Body>
    </Modal>
  )
}

export function Pick(props) {
  const { className, bodyAlignment } = props
  return (
    <Modal disableEnforceFocus={true} show={props.show} onHide={props.onHide} className={className}>
      <Modal.Header style={{ textAlign: 'center' }} closeButton>
        <div className="modal-logo-container">
          <img src={blueLogo} width={75} className="modal-logo" alt="logo" />
        </div>
      </Modal.Header>
      <Modal.Body style={{ textAlign: bodyAlignment ? bodyAlignment : 'center' }}>
        <Modal.Title style={{ textAlign: 'center' }}>{props.title || 'Are you sure?'}</Modal.Title>

        {props.body || 'This change will take effect immediately.'}
        <button className="modal-button" onClick={props.onCommit}>
          {props.commitMsg || 'Confirm Selections'}
        </button>
      </Modal.Body>
    </Modal>
  )
}

export function AccountInfo(props) {
  const { className, bodyAlignment, account = {} } = props
  return (
    <Modal disableEnforceFocus={true} show={props.show} onHide={props.onHide} className={className}>
      <Modal.Header style={{ textAlign: 'center' }} closeButton>
        <div className="modal-logo-container">
          <img src={blueLogo} width={75} className="modal-logo" alt="logo" />
        </div>
      </Modal.Header>
      <Modal.Body style={{ textAlign: bodyAlignment ? bodyAlignment : 'center' }}>
        <Modal.Title style={{ textAlign: 'center' }}>{account.email}</Modal.Title>
        <div className="row">
          <div className="col-sm-15">Account Cash: {account.cash}</div>
          <div className="col-sm-15">Account Pending: {account.pending}</div>
        </div>
        {/* <div><div/> */}
        <button className="modal-button" onClick={props.onCommit}>
          {props.commitMsg || 'Confirm Selections'}
        </button>
      </Modal.Body>
    </Modal>
  )
}
