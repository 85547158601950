import React from 'react'
import PortfolioIconSvg from '../../../assets/icons/portfolioIconSvg'
import FundsIconSvg from '../../../assets/icons/fundsIconSvg'
// import StudiesIconSvg from '../../../assets/icons/studiesIconSvg'
// import StudyBuilderIconSvg from '../../../assets/icons/studyBuilderIconSvg'
// import ClientsIconSvg from '../../../assets/icons/clientsIconSvg'

const getNavigationTabs = (user, currentTabLabel) => {
  const profileData = user.userProfile
  let tabs = [
    {
      tabLabel: 'My Models',
      routePath: '/models',
      trader: true,
      icon: <PortfolioIconSvg />,
      isSelected: false,
    },
    {
      tabLabel: 'Trade Signals',
      routePath: '/signals',
      trader: true,
      icon: <FundsIconSvg />,
      isSelected: false,
    },
    {
      tabLabel: 'Chart Monitor',
      routePath: '/chartmonitor',
      trader: true,
      icon: <PortfolioIconSvg />,
      isSelected: false,
    },
    {
      tabLabel: 'User Insight',
      routePath: '/userInsight',
      trader: true,
      icon: <PortfolioIconSvg />,
      isSelected: false,
    },
    // {
    //   tabLabel: 'Client Connect',
    //   routePath: '/clientConnect',
    //   fundManager: true,
    //   icon: <StudiesIconSvg />,
    //   isSelected: false,
    // },
    // {
    //   tabLabel: 'Studies',
    //   routePath: '/studies',
    //   developer: true,
    //   icon: <StudiesIconSvg />,
    //   isSelected: false,
    // },
    // {
    //   tabLabel: 'Study Builder',
    //   routePath: '/devStrategies',
    //   developer: true,
    //   icon: <StudyBuilderIconSvg />,
    //   isSelected: false,
    // },
  ]
  const trader = profileData.find(p => p.roleCode === 'trader')
  const admin = profileData.find(p => p.roleCode === 'admin')
  const fundManager = profileData.find(p => p.roleCode === 'fundManager')
  const developer = profileData.find(p => p.roleCode === 'developer')
  const assetManager = profileData.find(p => p.roleCode === 'assetManager')

  // fix this later to use role code.
  const retailTrader = user.userType === 'retail'

  if (!fundManager) {
    tabs = tabs.filter(tab => !tab.fundManager)
  }
  if (!trader) {
    tabs = tabs.filter(tab => !tab.trader)
  }
  if (!developer) {
    tabs = tabs.filter(tab => !tab.developer)
  }
  if (!retailTrader) {
    tabs = tabs.filter(tab => !tab.retailTrader)
  }
  if (!admin) {
    tabs = tabs.filter(tab => !tab.admin)
  }

  if (assetManager) {
    const modelsTab = tabs.find(tab => tab.tabLabel === 'My Models')
    modelsTab.routePath = '/assetManager'
  }

  tabs = tabs.map(tab => {
    if ('/' === currentTabLabel && tab.tabLabel === 'Smart Funds') {
      return { ...tab, isSelected: true }
    }
    if (tab.routePath === currentTabLabel) {
      return { ...tab, isSelected: true }
    }
    return tab
  })
  return tabs
}

export default getNavigationTabs
