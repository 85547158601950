import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Table from '../../common/components/Table'
import { Card } from '@material-ui/core'
import Widget from '../../common/components/Widget'
import { AccountInfo } from '../../common/components/Dialogs'

@inject(({ store: { auth, user } }) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    profile: auth.profile,
    getUserInsight: user.userInsightStore.getUserInsights,
    userInsights: user.userInsightStore.userInsights,
    userAccount: user.userInsightStore.userAccount,
    getUserAccount: user.userInsightStore.getUserAccount,
  }
})
@observer
export default class UserInsight extends Component {
  state = {
    showModal: false,
  }

  componentDidMount = async () => {
    window.document.title = 'Bob | User Insights'
    const { getUserInsight, isAuthenticated } = this.props
    await isAuthenticated()
    await getUserInsight()
  }

  showModal() {
    this.setState({ showModal: true })
  }

  render = () => {
    const { userInsights, getUserAccount, userAccount } = this.props
    const clientTableOpts = {
      rowsPerPage: 100,
      list: userInsights,
      listColumns: this.listColumns(getUserAccount),
    }
    return (
      <div className="row" style={{ width: '85%' }}>
        <div className="row">
          <div className="col-md-12">
            <Card>
              <Widget {...clientTableOpts} classProps={{ columnSize: 12 }} body={Table} />
            </Card>
          </div>
        </div>
        <AccountInfo
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          onCommit={() => this.setState({ showModal: false })}
          commitMsg="OK"
          title="Missing Account Number"
          body="Please enter your account number before linking your account."
          account={userAccount}
        />
      </div>
    )
  }
  listColumns(getUserAccount) {
    return [
      {
        displayName: 'Email',
        propPath: '/',
        displayFn: item => {
          const email = item.email
          window.setupDate = item.setupDate
          return (
            <div>
              <p
                onClick={async () => {
                  await getUserAccount(item.email)
                  this.setState({ showModal: true })
                }}
              >{`${email}`}</p>
            </div>
          )
        },
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Application Complete',
        propPath: 'applicationCompleteDate',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Verification Complete',
        propPath: 'verificationCompleteDate',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Banking Complete',
        propPath: 'bankingCompleteDate',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Last Deposit',
        propPath: 'lastDepositDate',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Deposit Amount',
        propPath: 'lastDepositAmount',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Last Time App Open',
        propPath: 'lastTimeOpenedApp',
        filterToolTip: true,
        filter: false,
      },
    ]
  }
}
