import React, { Component } from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Table from '../Admin/Table'
import { inject, observer } from 'mobx-react'


const styles = theme => ({
  typography: {
    useNextVariants: true,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
})

@inject(({ store: { model, auth, user } }) => {
  return {
    currentUser: user.currentUser,
    isAuthenticated: auth.isAuthenticated,
    auth0Profile: auth.profile,
    currentModel: model.currentModel,
    setCurrentModel: model.setCurrentModel,
    selectedSecurity: model.modelBuilderStore.selectedSecurity,
    setSelectedSecurity: model.modelBuilderStore.setSelectedSecurity,
    getModelCharts: model.getModelCharts
  }
})
@observer
class StepComplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reinvestEarnings: false,
      realizeLosses: false,
      reinvestDividends: false,
      targetPublishGroups: [],
      user: {},
    }
  }
  async componentDidMount() {
    const { currentModel, currentUser } = this.props
    const result = await axios.get(`/v0/getChartsByModelId/${currentModel._id}`)
    const { data : { charts = [], success }} = result
    if(!success)alert('Ant Fuck something up')
    this.setState({
      user: currentUser,
      charts
    })
  }

  publish = async (chartId) => {
    try{
      const { currentModel } = this.props
      const result = await axios.get(`/v0/publishChart/${chartId}`)
      const { data : { success}} = result
      if(!success)return alert(`Ant fucked up, this chart aint live`)
      const updatedCharts = await axios.get(`/v0/getChartsByModelId/${currentModel._id}`)
      const { data : { charts = []}} = updatedCharts
      this.setState({charts})
    }catch(e){
      alert(e.message)
    }
  }

  unPublish = async (chartId) => {
    try{
      const { currentModel } = this.props
      const result = await axios.get(`/v0/unPublishChart/${chartId}`)
      const { data : { success}} = result
      if(!success)return alert(`Ant fucked up again, Chart stll live`) 
      const updatedCharts = await axios.get(`/v0/getChartsByModelId/${currentModel._id}`)
      const { data : { charts = []}} = updatedCharts
      this.setState({charts})
    }catch(e){
      alert(e.message)
    }
  }

  render() {
    const { charts = [] } = this.state

    const tableOpts = {
      list: charts,
      listColumns: this.publishListColumns(),
    }
    return (
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Publish Options</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <div className="row">
                  <div className="col-md-12">
                    <Table {...tableOpts} />
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div className="row">
            <div className="col-md-12"></div>
          </div>
        </div>
      </div>
    )
  }

  publishListColumns() {
    return [
      {
        displayName: 'Ticker',
        propPath: 'id',
        style: {
          width: '15%',
        },
      },
      {
        displayName: 'Company Name',
        propPath: 'companyName',
        style: {
          width: '15%',
        },
      },
      {
        displayName: 'Status',
        propPath: '/',
        displayFn: item => { 
          if(item.slot)return (<div>TRADING</div>)
          return (<div>NOT TRADING</div>)
        },
        filter: false,
        style: {
          width: '10%',
        },
      },
      {
        displayName: 'Ticker',
        propPath: '/',
        displayFn: item => {
          if(item.published)return (<Button onClick={this.unPublish.bind(null,item._id)}>UNPUBLISH</Button>)
          return (<Button onClick={this.publish.bind(null, item._id)}>PUBLISH</Button>)
        },
        filter: false,
        style: {
          width: '10%',
        },
      },
    ]
  }
}

export default withStyles(styles)(StepComplete)
