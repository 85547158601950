import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { StyledChartMonitor } from './styles'

@inject(({ store: { auth, app, ui } }) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    getOfflineCharts: app.chartMonitorStore.getOfflineCharts,
    offlineCharts: app.chartMonitorStore.offlineCharts,
    totalOffline: app.chartMonitorStore.totalOffline,
    totalCharts: app.chartMonitorStore.totalCharts,
  }
})
@observer
export default class ChartMonitor extends Component {
  state = {}

  componentDidMount = async () => {
    window.document.title = 'Bob | ChartMonitor'
    const { isAuthenticated, getOfflineCharts } = this.props
    await isAuthenticated()
    await getOfflineCharts()
    this.monitor()
  }

  monitor() {
    const { getOfflineCharts } = this.props
    setInterval(async () => {
      await getOfflineCharts()
    }, 5000)
  }

  render = () => {
    const {
      totalOffline,
      totalCharts,
      offlineCharts: { charts = [] },
    } = this.props
    charts.sort((a, b) => {
      return a.lastQuote - b.lastQuote
    })
    return (
      <StyledChartMonitor>
        <div className="row">
          <div className="row">
            <div style={{ marginLeft: '12em' }}>
              <h1>{`Chart Monitor`}</h1>
              <h4>{`Total Active Charts: ${totalCharts}`}</h4>
              <h4>{`Total Offline: ${totalOffline}`}</h4>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 50 }}>
          <table border="1" width={500}>
            <th>Symbol</th>
            <th>Last Quote</th>
            <tbody>
              {charts.map(chart => {
                return (
                  <tr style={{ height: 10 }}>
                    <td>{chart.id}</td>
                    <td>
                      {moment(chart.lastQuote).format('MM/DD/YYYY:hh:mm:ss')}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </StyledChartMonitor>
    )
  }
}
