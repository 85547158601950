import { action, set, observable } from 'mobx'
import HeaderStore from '../common/components/Header/store'
import MetricStore from '../scenes/Metric/store'
import ClientStore from '../scenes/Funds/FundDetails/Clients/store'
import ProfileStore from '../scenes/Profile/store'
import SmartFundStore from '../scenes/SmartFund/store'
import UserState from './models/user'
import UserInsightStore from '../scenes/UserInsight/store'

class User {
  @observable currentUser = UserState
  @observable clients = []
  @observable isRetail = false
  @observable linkedBroker = false
  @observable retailProfile = {}

  constructor(rootStore) {
    this.rootStore = rootStore
    this.auth = this.rootStore.auth
    this.headerStore = new HeaderStore(this)
    this.metricStore = new MetricStore(this)
    this.clientStore = new ClientStore(this)
    this.smartFundStore = new SmartFundStore(this)
    this.profileStore = new ProfileStore(this)
    this.userInsightStore = new UserInsightStore(this)
  }

  @action('User Store | setUser')
  setUser = user => {
    console.log(user)
    const { userType, linkedBrokers } = user
    if (userType === 'retail') {
      this.isRetail = true
      this.getRetailProfile()
    }
    if (linkedBrokers && linkedBrokers.find(broker => broker.linked === true)) {
      this.linkedBroker = true
    }
    set(this.currentUser, user)
    return this.currentUser
  }

  @action('User Store | updateUserAccountNum')
  updateUserAccountNum = async accountNum => {
    try {
      const { api } = this.rootStore
      const result = await api.post('/v0/user.update', {
        accountNum,
      })
      const { data } = result
      const { success, message } = data
      if (!success) throw new Error(message)
    } catch (e) {
      console.log(` ISSUE IN USER STORE | ${e.message}`)
    }
  }

  @action('User Store | addNewUser')
  addNewUser = async profile => {
    try {
      const { api } = this.rootStore
      const { sub, name: email, nickname: firstName } = profile
      const result = await api.post('/v0/user.add', {
        UserId: sub,
        active: true,
        roleAssignment: ['5ed54898db8825229d5d8596'],
        userType: 'retail',
        email,
        firstName,
        lastName: '',
      })
      const { data } = result
      const { success, message, user } = data
      if (!success) throw new Error(message)
      this.currentUser = Array.isArray(user) ? user[0] : user
    } catch (e) {
      console.log(` ISSUE IN USER STORE | ${e.message}`)
    }
  }

  @action('User Store | getUserClients')
  getUserClients = async () => {
    try {
      const { api } = this.rootStore
      const params = { groupView: false }
      const result = await api.get('/v0/clients', { params })
      const { data } = result
      const { clients } = data
      set(this.clients, clients)
      return clients
    } catch (e) {
      console.log(` ISSUE IN USER STORE | ${e.message}`)
    }
  }

  @action('User Store | getUserClients')
  getFundQualifiedClients = async () => {
    try {
      const {
        api,
        fund: { currentFund, setQualifiedClients },
      } = this.rootStore
      const params = { groupView: false }
      const result = await api.get('/v0/clients', { params })
      const otherFunds = await this.smartFundStore.getUserSmartFunds()
      const filteredFunds = otherFunds.filter(o => o._id !== currentFund._id)
      const { data } = result
      const { clients } = data

      clients.forEach(c => {
        filteredFunds.forEach(f => {
          const found = f.clients
            ? f.clients.find(client => String(client._id) === String(c._id))
            : false
          if (found) c.alreadyInAFund = found
        })
        const accountAllocation = currentFund.accountAllocation ? currentFund.accountAllocation : 0
        const percentOfAccount = c.totalAccountValue * accountAllocation
        c.aum = percentOfAccount
        const bobBudget = percentOfAccount - (c.securitiesValue ? c.securitiesValue : 0)
        c.bobBudget = bobBudget > 0 ? bobBudget : 0
        c.reserves =
          currentFund.reservePercentage *
          (c.actualTotalAccountValue ? c.actualTotalAccountValue : c.totalAccountValue)
        c.bobBuyingPower = (c.tdaCash < c.bobBudget ? c.tdaCash : c.bobBudget) - c.reserves
      })
      const qualifiedClients = clients.filter(c => {
        const fundBuyIn = currentFund.buyIn
        return (
          c.bobBuyingPower > fundBuyIn &&
          this.reserveCheck(c.tdaCash, fundBuyIn, c.reserves) &&
          !c.alreadyInAFund
        )
      })
      set(this.clients, qualifiedClients)
      setQualifiedClients(qualifiedClients)
      return qualifiedClients
    } catch (e) {
      console.log(` ISSUE IN USER STORE | ${e.stack}`)
    }
  }

  @action('User Store | isClientQualifiedForFund')
  isClientQualifiedForFund = client => {
    const {
      fund: { currentFund, updateClientOnFund },
    } = this.rootStore
    const qualify = this.setClientValuesForFund(client, currentFund)
    updateClientOnFund(client)
    return qualify
  }

  @action('User Store | evaluateClientForFund')
  evaluateClientForFund = (client, fund) => {
    const {
      fund: { updateClientOnFund },
    } = this.rootStore
    const qualify = this.setClientValuesForFund(client, fund)
    if (qualify) {
      updateClientOnFund(client)
    }
    return qualify
  }

  setClientValuesForFund = (client, fund) => {
    const { buyIn } = fund
    const accountAllocation = client.accountAllocation
      ? client.accountAllocation
      : fund.accountAllocation
    const reservePercentage = client.reservePercentage
      ? client.reservePercentage
      : fund.reservePercentage
    const percentOfAccount = client.totalAccountValue * accountAllocation
    client.aum = percentOfAccount
    const bobBudget = percentOfAccount - (client.securitiesValue ? client.securitiesValue : 0)
    client.bobBudget = bobBudget > 0 ? bobBudget : 0
    client.reserves =
      reservePercentage *
      (client.actualTotalAccountValue ? client.actualTotalAccountValue : client.totalAccountValue)
    const fundBuyIn = buyIn
    client.bobBuyingPower =
      (client.tdaCash < client.bobBudget ? client.tdaCash : client.bobBudget) - client.reserves
    const qualify =
      client.bobBuyingPower > fundBuyIn &&
      this.reserveCheck(client.tdaCash, fundBuyIn, client.reserves)
    client.freezeBuys = qualify ? false : true
    client.fundDisqualified = qualify ? false : true
    return qualify
  }

  @action('User Store | getUserAndGroupClients')
  getUserAndGroupClients = async () => {
    try {
      const { api } = this.rootStore
      const params = { groupView: true }
      const result = await api.get('/v0/clients', { params })
      const { data } = result
      const { clients } = data
      return clients
    } catch (e) {
      console.log(` ISSUE IN USER STORE | ${e.message}`)
    }
  }

  @action('User Store | reserveCheck')
  reserveCheck = (cash, buyIn, reserve) => {
    return cash - buyIn > reserve
  }

  @action('User Store | getRetailProfile')
  getRetailProfile = async () => {
    try {
      // const { api } = this.rootStore
      // const result = await api.get('/v0/retail-profile-state')
      // const { data } = result
      set(this.retailProfile, {})
    } catch (e) {
      console.log(` ISSUE IN USER STORE | ${e.message}`)
    }
  }
}

export default User
