import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Filter from '@material-ui/icons/FilterList'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Tooltip from '@material-ui/core/Tooltip'

import uuid from 'uuid'
import objectPath from 'simple-object-path'
import { typography, colors } from '../../../assets/styles'
import { isObservable } from 'mobx'
import moment from 'moment'

// import '../../../App/App.scss'

const op = (obj, path) => (path === '/' ? obj : objectPath(obj, path))
const isFunction = fn => typeof fn === 'function'

const StyledTableHeader = withStyles(theme => ({
  root: {
    fontSize: typography.extraSmallFontSize,
    fontFamily: typography.baseFontFamily,
  },
}))(TableHead)

const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${colors.borderGrey}`,
  },
  body: {
    fontSize: typography.extraSmallFontSize,
    fontFamily: typography.baseFontFamily,
    color: colors.textGrey,
  },
}))(TableCell)

export default class CoolTable extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.setInitialData()
  }

  setInitialData() {
    const { listColumns = [], tableConfig = {} } = this.props
    const newListColumns = listColumns.map(item => {
      item.id = item.id || uuid.v4()
      return item
    })
    const { filters, page: tablePage = 0, rowsPerPage } = tableConfig
    this.setState({ listColumns: newListColumns, filters, tablePage, rowsPerPage })
  }

  componentDidUpdate(props) {
    if (this.props !== props) {
      this.setInitialData()
    }
  }

  changePage(...a) {
    console.log(a)
  }

  handleChangePage(event, tablePage) {
    const { onPageChange, tableId } = this.props
    if (isFunction(onPageChange)) {
      const { rowsPerPage = 5, filters = [] } = this.state
      onPageChange(tableId, tablePage, rowsPerPage, filters)
    }
    this.setState({ tablePage })
  }

  handleChangeRowsPerPage(event) {
    const { onPageChange, tableId } = this.props
    const rowsPerPage = event.target.value
    if (isFunction(onPageChange)) {
      const { tablePage, filters = [] } = this.state
      onPageChange(tableId, tablePage, rowsPerPage, filters)
    }
    this.setState({ rowsPerPage })
  }

  handleFilterValues(column, e) {
    const { filters = [], tablePage = 0, rowsPerPage = 5 } = this.state
    const { onFilterChange, tableId } = this.props
    const value = e.target.value
    const existing = filters.find(f => f.column.id === column.id)
    if (existing) {
      filters.splice(
        filters.findIndex(f => f.column.id === column.id),
        1
      )
    }
    filters.push({ column, value })
    if (isFunction(onFilterChange)) {
      onFilterChange(tableId, tablePage, rowsPerPage, filters)
    }
    this.setState({ filters, tablePage: 0 })
  }
  colValue(obj, col = {}, filter = false) {
    const { displayFn, filterVal } = col
    if (filter) {
      if (isFunction(filterVal)) {
        const item = op(obj, col.propPath)
        return filterVal(item)
      }
    }
    if (isFunction(displayFn)) {
      const item = op(obj, col.propPath)
      return filterVal(item)
    }
  }
  safeEval(statement, comparingValue) {
    const knownOperators = [
      '>',
      '<',
      '>=',
      '<=',
      '=',
      '!=',
      '<>',
      'eq',
      'gt',
      'gte',
      'lt',
      'lte',
      '+',
      '-',
    ]
    const knownOperatorOverride = {
      '<>': '!=',
      eq: '==',
      '=': '==',
      gt: '>',
      lt: '<',
      gte: '>=',
      lte: '<=',
      '+': '>',
      '-': '<',
    }
    if (typeof statement === 'string') {
      for (let knownOperator of knownOperators) {
        if (~statement.indexOf(knownOperator)) {
          const operator = knownOperatorOverride[knownOperator] || knownOperator
          const statementValue = statement.replace(knownOperator, '').replace(',', '')
          const numberValue = parseFloat(statementValue)
          if (!isNaN(numberValue) && typeof numberValue === 'number') {
            try {
              // eslint-disable-next-line
              return eval(`${comparingValue} ${operator} ${numberValue}`)
            } catch (e) {
              // console.warn('Eval error', e)
            }
          }
        }
      }
    }
  }
  handleTableSort(column) {
    const { list = [] } = this.props
    const { listColumns = [] } = this.state
    const { propPath, sortProp, isDateSort = false } = column
    const path = sortProp || propPath
    listColumns.forEach(c => {
      if (c.id !== column.id) c.sort = undefined
    })
    const ascDateSortFunction = (a, b) =>
      moment(op(a, path)).isAfter(moment(op(b, path))) ? -1 : 0
    const descDateSortFunction = (a, b) =>
      moment(op(b, path)).isAfter(moment(op(a, path))) ? -1 : 0
    if (column.sort === 'asc') {
      column.sort = 'desc'
      let sortFunction = (a, b) => (op(a, path) > op(b, path) ? 0 : -1)
      if (isDateSort) sortFunction = descDateSortFunction
      if (isObservable(list)) {
        list.replace(list.slice().sort(sortFunction))
      } else {
        list.sort(sortFunction)
      }
    } else if (column.sort === 'desc') {
      column.sort = 'asc'
      let sortFunction = (a, b) => (op(a, path) > op(b, path) ? -1 : 0)
      if (isDateSort) sortFunction = ascDateSortFunction
      if (isObservable(list)) {
        list.replace(list.slice().sort(sortFunction))
      } else {
        list.sort(sortFunction)
      }
    } else {
      column.sort = 'desc'
      let sortFunction = (a, b) => (op(a, path) > op(b, path) ? 0 : -1)
      if (isDateSort) sortFunction = descDateSortFunction
      if (isObservable(list)) {
        list.replace(list.slice().sort(sortFunction))
      } else {
        list.sort(sortFunction)
      }
    }
    this.forceUpdate()
  }
  render() {
    const { tablePage = 0, rowsPerPageOptions = this.props.rowsPerPageOptions || 5, rowsPerPage = this.props.rowsPerPage || 5, filters = [] } = this.state
    const { selectable, onSelect, list = [] } = this.props

    let { listColumns = [] } = this.state
    if (!listColumns.length) {
      listColumns = this.props.listColumns
    }
    const filtered = list.filter(column => {
      return filters.every(filterObject => {
        const propPath = op(filterObject, 'column/propPath')
        const filterVal = op(filterObject, 'column/filterVal')
        const displayFn = op(filterObject, 'column/displayFn')
        const defaultVal = op(filterObject, 'column/default')
        const numericEval = op(filterObject, 'column/numericEval')
        let columnValue = op(column, propPath) || defaultVal || ''
        if (isFunction(filterVal) || isFunction(displayFn)) {
          const fn = isFunction(filterVal) ? filterVal : displayFn
          columnValue = fn(columnValue)
        }
        if (typeof columnValue !== 'string') {
          columnValue = String(columnValue).toString()
        }
        if (numericEval === true) {
          const evalResult = this.safeEval(filterObject.value, columnValue)
          if (typeof evalResult === 'boolean') {
            return evalResult
          }
        }

        if (typeof filterObject.value !== 'string') {
          filterObject.value = String(filterObject.value).toString()
        }
        if (columnValue.toUpperCase().includes(filterObject.value.toUpperCase())) return true
        return false
      })
    })
    const page = []
      .concat(filtered)
      .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
    return (
      <div>
        <Table>
          <StyledTableHeader>
            <TableRow>
              {listColumns.map((col, idx) => {
                const filter = col.filter === false ? false : true
                const filterObject = filters.find(f => f.column.id === col.id) || {}
                return (
                  <TableCell key={idx} colSpan={1}>
                    {col.colDisplayFn && col.colDisplayFn()}
                    {col.filterToolTip && (
                      <div>
                        <Tooltip title={`Filter ${col.displayName}` || ''} placement="top">
                          <TextField
                            autoComplete="off"
                            value={filterObject.value || ''}
                            onChange={this.handleFilterValues.bind(this, col)}
                            label={<Filter />}
                            fullWidth={true}
                          />
                        </Tooltip>
                      </div>
                    )}
                    {filter && (
                      <TextField
                        autoComplete="off"
                        onChange={this.handleFilterValues.bind(this, col)}
                        value={filterObject.value || ''}
                        label={
                          <span>
                            <small>
                              <Filter /> {`${col.displayName}`}
                            </small>
                          </span>
                        }
                        fullWidth={true}
                      />
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              {listColumns.map(col => {
                return (
                  <TableCell
                    onClick={
                      col.sortable === false ? () => true : this.handleTableSort.bind(this, col)
                    }
                    key={col.id}
                  >
                    {col.displayName} {col.sort === 'asc' && <ArrowUpward fontSize="small" />}{' '}
                    {col.sort === 'desc' && <ArrowDownward fontSize="small" />}
                  </TableCell>
                )
              })}
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {page.map((item, idx) => {
              return (
                <TableRow
                  key={idx}
                  className={selectable ? 'table-selectable-hover' : ''}
                  onClick={selectable ? () => onSelect(item) : () => true}
                >
                  {listColumns.map(col => {
                    return (
                      <StyledTableCell key={col.id}>
                        {col.displayFn
                          ? col.displayFn(op(item, col.propPath))
                          : op(item, col.propPath) || col.default || ''}
                      </StyledTableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          count={filtered.length}
          page={tablePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[rowsPerPageOptions]}
          component="div"
          onChangePage={this.handleChangePage.bind(this)}
          onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
        />
      </div>
    )
  }
}
