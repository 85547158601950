import { action, observable } from 'mobx'

export default class FocusStore {
  @observable focusTimes = {games : []}
  @observable numbersUsed = []

  constructor(appStore) {
    this.rootStore = appStore.rootStore
    this.log = this.rootStore.log
  }

  @action('Focus Store | getUserFocusTimes')
  getUserFocusTimes = async () => {
    try {
      const { api } = this.rootStore
      const result = await api.get('/v0/getUserFocusTimes')
      const {
        data: { focusTimes = [] },
      } = result
      this.focusTimes.games = focusTimes
    } catch (e) {
      this.log.warn(`Focus Store | getUserFocusTimes ${e.stack}`)
    }
  }

  @action('Focus Store | getUserFocusTimes')
  recordFocusTime = async focus => {
    try {
      const { api } = this.rootStore
      const result = await api.post('/v0/recordFocusTime', {
        ...focus,
        numbersUsed: this.numbersUsed,
      })
      const {
        data: { success, message },
      } = result
      if (!success) throw new Error(message)
    } catch (e) {
      this.log.warn(`Focus Store | recordFocusTime ${e.message}`)
    }
  }

  @action('Focus Store | shuffle')
  shuffle(array) {
    return array.sort(() => Math.random() - 0.5)
  }

  @action('Focus Store | buildGame')
  buildGame = () => {
    const gameRows = []
    for (let i = 1; i < 101; i++) {
      this.numbersUsed.push(i)
    }
    this.numbersUsed = this.shuffle(this.numbersUsed)
    let count = 0
    let rows = []
    let currentRow = 1
    let rowMap = {
      1: 'rowOne',
      2: 'rowTwo',
      3: 'rowThree',
      4: 'rowFour',
      5: 'rowFive',
      6: 'rowSix',
      7: 'rowSeven',
      8: 'rowEight',
      9: 'rowNine',
      10: 'rowTen',
    }
    for (const number of this.numbersUsed) {
      if (count === 9) {
        count += 1
        rows.push(number)
        gameRows.push({ [rowMap[currentRow]]: rows })
        rows = []
        count = 0
        currentRow += 1
        continue
      }
      rows.push(number)
      count += 1
    }

    return gameRows
  }
}
