import { action, observable } from 'mobx'

export default class ChartMonitorStore {
  @observable offlineCharts = {charts : []}
  @observable totalOffline = 0
  @observable totalCharts = 0

  constructor(appStore) {
    this.rootStore = appStore.rootStore
    this.log = this.rootStore.log
  }

  @action('ChartMonitor Store | getOfflineCharts')
  getOfflineCharts = async () => {
    try {
      const { api } = this.rootStore
      const result = await api.get('/v0/getOfflineCharts')
      const {
        data: { charts = [], totalOffline, totalCharts },
      } = result
      this.offlineCharts.charts = charts
      this.totalOffline = totalOffline
      this.totalCharts = totalCharts
    } catch (e) {
      this.log.warn(`ChartMonitor Store | getOfflineCharts ${e.stack}`)
    }
  }

}
