import React, { Component } from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Table from '../Admin/Table'
import { inject, observer } from 'mobx-react'
import { Modal } from 'react-bootstrap'
import blueLogo from '../../assets/img/bob-logo-modal.png'
import StyledUserProfileMenu from './styles'
import ChevronDownIcon from '../../assets/icons/chevron-down-icon.svg'
import { io } from 'socket.io-client'
import InputField from '../../common/components/InputField'


const styles = theme => ({
  typography: {
    useNextVariants: true,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
})

@inject(({ store: { model, auth, user } }) => {
  return {
    currentUser: user.currentUser,
    isAuthenticated: auth.isAuthenticated,
    auth0Profile: auth.profile,
    currentModel: model.currentModel,
    setCurrentModel: model.setCurrentModel,
    selectedSecurity: model.modelBuilderStore.selectedSecurity,
    setSelectedSecurity: model.modelBuilderStore.setSelectedSecurity,
    getModelCharts: model.getModelCharts,
    getTdaAccounts: model.modelBuilderStore.getTdaAccounts,
    managedAccounts: model.modelBuilderStore.managedAccounts,
  }
})
@observer
class StepPosition extends Component {
  state = {
    dropdownOpen: false,
    order: {}
  }
  constructor(props) {
    super(props)
    this.state = {
      reinvestEarnings: false,
      realizeLosses: false,
      reinvestDividends: false,
      targetPublishGroups: [],
      user: {},
      quotes: {}
    }
    this.socket = {}
  }
  async componentDidMount() {
    const { currentUser, getTdaAccounts } = this.props
    await getTdaAccounts()
    // const result = await axios.get(`/v0/tda.getPositions`)
    // const { data : { positions = [], success }} = result
    // if(!success)alert('Ant Fuck something up')
    this.setState({
      user: currentUser,
      showConfirmModal: false
    })
    const result = await axios.get(`/v0/feeder`)
    const {data: { path, url } } = result
    this.socket = io(url,{path})
  }

  getPositions = async (accountNum)=>{
    const { quotes } = this.state
    const result = await axios.get(`/v0/tda.getPositions/${accountNum}`)
    const { data : { positions = [], success }} = result
    for(const position of positions){
      const { symbol } = position
      if(symbol.includes('MMDA1'))continue
      const security = symbol.length > 5 ? { optionSymbol: symbol } : { symbol }
      console.log(security)
      this.socket.emit('addSecurity', security)
      this.socket.once(symbol, () => {
        console.log(`IM CONNECTED TO ${symbol} ROOM`)
      })
      this.socket.on(symbol, data => {
        quotes[symbol] = data
        this.setState({
          quotes
        })
      })
      }
    if(!success)alert('Ant Fuck something up')
    this.setState({positions})
  }
  
  sell = async () => {
    try{
      const result = await axios.post(`/v0/tda.sell`,this.state.order)
      const { data : { success}} = result
      if(!success)return alert(`Ant fucked up, this chart aint live`)
      setTimeout(async ()=>{
          const response = await axios.get(`/v0/tda.getPositions`)
          const { data : { positions = [] }} = response
          this.setState({positions})
      }, 10000)
    }catch(e){
      alert(e.message)
    }
  }

  toggleDropDown(){
    return this.state.dropdownOpen ? this.setState({ dropdownOpen: false}) : this.setState({ dropdownOpen: true})
  }

  hideModal(){
    this.setState({ showConfirmModal: false})
  }

  showModal(order){
    this.setState({showConfirmModal: true, order})
  }

  setShares(shares){
    const { order } = this.state
    order.quantity = +shares
    this.setState({order})
  }

  render() {
    const { positions = [], dropdownOpen, order = {} } = this.state
    const { managedAccounts : { clients }} = this.props
    const tableOpts = {
      list: positions,
      listColumns: this.publishListColumns(this),
      rowsPerPageOptions: 25,
      rowsPerPage: 50
    }
    return (
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Active Positions</Typography>
            </ExpansionPanelSummary>
            <StyledUserProfileMenu>
            <li>   
            <button
                className="dropdown-button impersonation-button"
                onClick={() => { this.toggleDropDown()}}
              >
                Managed Accounts
                <img src={ChevronDownIcon} alt="Select profile chevron icon" />
              </button>             
              { dropdownOpen && <ul className="impersonation-menu">
                  {clients.map((usr, key) => (
                    <li key={key}>
                      <button
                        className="impersonation-user"
                        onClick={() => {
                          this.getPositions(usr.accountNum)
                          this.toggleDropDown()
                        }}
                      >
                        {usr.firstName}
                      </button>
                    </li>
                  ))}
                </ul>}
            </li>
      </StyledUserProfileMenu>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <div className="row">
                  <div className="col-md-12">
                    <Table {...tableOpts} />
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div className="row">
            <div className="col-md-12"></div>
          </div>
        </div>
        <Modal
          show={this.state.showConfirmModal}
          onHide={this.hideModal.bind(this)}
          size="lg"
                >
          <Modal.Header style={{ textAlign: 'center' }} closeButton>
            <div className="modal-logo-container">
            <img src={blueLogo} width={75} className="modal-logo" alt="logo" />
            </div>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
            <Modal.Title>How many shares?</Modal.Title>
            <div className="row">
                    <div className="col-md-12">
                      <InputField
                        type="number"
                        label="Shares (required)"
                        customSize="70%"
                        handleOnChange={str => {
                          this.setShares(str)
                          console.log(str)
                        }}
                        initialValue={String(order.quantity)}
                        isRequired
                      />
                    </div>
                  </div>

            <Button
            style={{marginTop: 25}}
            variant="contained"
            color="secondary"
            className="provide-payment-button"
            onClick={this.sell.bind(this)}
          >
            EXECUTE ORDER
          </Button>
          </Modal.Body>
        </Modal>
      </div>
    )
  }

  publishListColumns(_this) {
    const {quotes} = _this.state
    return [
      {
        displayName: 'Account',
        propPath: 'accountNumber',
        style: {
          width: '15%',
        },
      },
      {
        displayName: 'Ticker',
        propPath: 'symbol',
        style: {
          width: '15%',
        },
      },
      {
        displayName: 'Cost Basis',
        propPath: 'costBasis',
        style: {
          width: '15%',
        },
      },
      {
        displayName: 'P/L',
        propPath: '/',
        displayFn: item => {
          const { costBasis, symbol }  = item
          if(!quotes[symbol])quotes[symbol]={}
          if(!quotes[symbol].mark && !quotes[symbol].Last && !quotes[symbol].last) return (<span>waiting...</span>)
          const pL = (((quotes[symbol].mark || quotes[symbol].Last || quotes[symbol].last) - costBasis) / costBasis) * 100
          return (<span style={{color: pL > 1 ? 'green':'red'}}>{`${pL > 0 ? '+':'-'}${parseFloat(Math.abs(pL)).toFixed(2)}%`}</span>)
        },
        style: {
          width: '15%',
        },
      },
      {
        displayName: 'Shares',
        propPath: 'quantity',
        style: {
          width: '15%',
        },
      },
      {
        displayName: 'Place Order',
        propPath: '/',
        displayFn: item => {
          return (<Button onClick={_this.showModal.bind(_this, {accountNumber: item.accountNumber, symbol: item.symbol, quantity: item.quantity})}>SELL</Button>)
        },
        filter: false,
        style: {
          width: '10%',
        },
      },
    ]
  }
}

export default withStyles(styles)(StepPosition)
