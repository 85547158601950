import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { Modal } from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import blueLogo from '../../assets/img/bob-logo-modal.png'
import { StyledFocus } from './styles'

@inject(({ store: { auth, app, ui } }) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    getUserFocusTimes: app.focusStore.getUserFocusTimes,
    recordFocusTime: app.focusStore.recordFocusTime,
    buildGame: app.focusStore.buildGame,
    focusTimes: app.focusStore.focusTimes,
  }
})
@observer
export default class Focus extends Component {
  state = {
    selected: [],
    rowOne: [],
    rowTwo: [],
    rowThree: [],
    rowFour: [],
    rowFive: [],
    rowSix: [],
    rowSeven: [],
    rowEight: [],
    rowNine: [],
    rowTen: [],
    time: '00:00:00',
    startTime: false,
    stopTime: false,
    hr: 0,
    min: 0,
    sec: 0,
    showFinished: false,
  }

  componentDidMount = async () => {
    window.document.title = 'Bob | Focus'
    const { isAuthenticated, getUserFocusTimes } = this.props
    await isAuthenticated()
    await getUserFocusTimes()
  }

  async selectNumber(number) {
    const { selected } = this.state
    const { recordFocusTime } = this.props
    let found = selected.find(s => s === number)
    if (selected.length === 0 && number !== 1) return
    if (found === 0) found = true
    if (found) return
    if (selected.length > 0 && selected[selected.length - 1] - number !== -1) return
    selected.push(number)
    this.setState({ selected: selected })
    if (selected.length === 100) {
      let { timer, time } = this.state
      clearTimeout(timer)
      await recordFocusTime({ time })
      this.setState({ showFinished: true })
    }
  }

  startTimer() {
    this.timerCycle()
  }

  stopTimer() {
    let { timer } = this.state
    clearTimeout(timer)
  }

  timerCycle() {
    let { stopTime, hr, min, sec } = this.state
    if (stopTime === false) {
      sec = parseInt(sec)
      min = parseInt(min)
      hr = parseInt(hr)

      sec = sec + 1

      if (sec === 60) {
        min = min + 1
        sec = 0
      }
      if (min === 60) {
        hr = hr + 1
        min = 0
        sec = 0
      }

      if (sec < 10 || sec === 0) {
        sec = '0' + sec
      }
      if (min < 10 || min === 0) {
        min = '0' + min
      }
      if (hr < 10 || hr === 0) {
        hr = '0' + hr
      }

      this.setState({
        timer: setTimeout(this.timerCycle.bind(this), 1000),
        time: `${hr + ':' + min + ':' + sec}`,
        hr,
        min,
        sec,
      })
    }
  }

  resetTimer() {
    this.setState({ time: '00:00:00', selected: [] })
  }

  async hideModal() {
    const { getUserFocusTimes } = this.props
    await getUserFocusTimes()
    this.setState({ showFinished: false })
  }

  async play() {
    const { buildGame } = this.props
    const gameRows = buildGame()
    for (const row of gameRows) {
      this.setState({ ...row })
    }
    this.startTimer()
  }

  async reset() {
    this.setState({
      rowOne: [],
      rowTwo: [],
      rowThree: [],
      rowFour: [],
      rowFive: [],
      rowSix: [],
      rowSeven: [],
      rowEight: [],
      rowNine: [],
      rowTen: [],
      time: '00:00:00',
      startTime: false,
      stopTime: false,
      hr: 0,
      min: 0,
      sec: 0,
      showFinished: false,
    })
    this.resetTimer()
  }

  render = () => {
    const {
      rowOne,
      rowTwo,
      rowThree,
      rowFour,
      rowFive,
      rowSix,
      rowSeven,
      rowEight,
      rowNine,
      rowTen,
      selected,
      time,
    } = this.state
    const { focusTimes = {} } = this.props
    const { games = [] } = focusTimes
    return (
      <StyledFocus>
        <div className="row">
          <div className="row">
            <div style={{ marginLeft: '12em' }}>
              <h1>{time}</h1>
            </div>
          </div>
          <div className="row">
            {rowOne.length === 0 && (
              <div>
                <div style={{}}>
                  <Button
                    style={{ marginTop: 5, marginBottom: 10, marginLeft: '13em' }}
                    variant="contained"
                    className="provide-payment-button"
                    onClick={this.play.bind(this)}
                  >
                    Start Grid Now
                  </Button>
                </div>
                <div
                  style={{
                    marginLeft: 15,
                    width: 500,
                    height: 500,
                    backgroundColor: 'black',
                    position: 'absolute',
                  }}
                ></div>
              </div>
            )}
            <div className="col-md-2">
              <table border="1" width={500} height={500}>
                <tbody>
                  <tr>
                    {rowOne.map(item => (
                      <td>
                        <div
                          style={
                            selected.find(s => s === item)
                              ? {
                                  zIndex: 12,
                                  marginLeft: 15,
                                  marginTop: 8,
                                  position: 'absolute',
                                  width: 15,
                                  height: 2,
                                  borderBottomheight: 1,
                                  borderBottomColor: 'red',
                                  backgroundColor: 'black',
                                }
                              : undefined
                          }
                        ></div>
                        <strong
                          style={{ marginLeft: 15, color: 'black' }}
                          onClick={this.selectNumber.bind(this, item)}
                        >
                          {item}
                        </strong>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {rowTwo.map(item => (
                      <td>
                        <div
                          style={
                            selected.find(s => s === item)
                              ? {
                                  zIndex: 12,
                                  marginLeft: 15,
                                  marginTop: 8,
                                  position: 'absolute',
                                  width: 15,
                                  height: 2,
                                  borderBottomheight: 1,
                                  borderBottomColor: 'red',
                                  backgroundColor: 'black',
                                }
                              : undefined
                          }
                        ></div>
                        <strong
                          style={{ marginLeft: 15, color: 'black' }}
                          onClick={this.selectNumber.bind(this, item)}
                        >
                          {item}
                        </strong>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {rowThree.map(item => (
                      <td>
                        <div
                          style={
                            selected.find(s => s === item)
                              ? {
                                  zIndex: 12,
                                  marginLeft: 15,
                                  marginTop: 8,
                                  position: 'absolute',
                                  width: 15,
                                  height: 2,
                                  borderBottomheight: 1,
                                  borderBottomColor: 'red',
                                  backgroundColor: 'black',
                                }
                              : undefined
                          }
                        ></div>
                        <strong
                          style={{ marginLeft: 15, color: 'black' }}
                          onClick={this.selectNumber.bind(this, item)}
                        >
                          {item}
                        </strong>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {rowFour.map(item => (
                      <td>
                        <div
                          style={
                            selected.find(s => s === item)
                              ? {
                                  zIndex: 12,
                                  marginLeft: 15,
                                  marginTop: 8,
                                  position: 'absolute',
                                  width: 15,
                                  height: 2,
                                  borderBottomheight: 1,
                                  borderBottomColor: 'red',
                                  backgroundColor: 'black',
                                }
                              : undefined
                          }
                        ></div>
                        <strong
                          style={{ marginLeft: 15, color: 'black' }}
                          onClick={this.selectNumber.bind(this, item)}
                        >
                          {item}
                        </strong>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {rowFive.map(item => (
                      <td>
                        <div
                          style={
                            selected.find(s => s === item)
                              ? {
                                  zIndex: 12,
                                  marginLeft: 15,
                                  marginTop: 8,
                                  position: 'absolute',
                                  width: 15,
                                  height: 2,
                                  borderBottomheight: 1,
                                  borderBottomColor: 'red',
                                  backgroundColor: 'black',
                                }
                              : undefined
                          }
                        ></div>
                        <strong
                          style={{ marginLeft: 15, color: 'black' }}
                          onClick={this.selectNumber.bind(this, item)}
                        >
                          {item}
                        </strong>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {rowSix.map(item => (
                      <td>
                        <div
                          style={
                            selected.find(s => s === item)
                              ? {
                                  zIndex: 12,
                                  marginLeft: 15,
                                  marginTop: 8,
                                  position: 'absolute',
                                  width: 15,
                                  height: 2,
                                  borderBottomheight: 1,
                                  borderBottomColor: 'red',
                                  backgroundColor: 'black',
                                }
                              : undefined
                          }
                        ></div>
                        <strong
                          style={{ marginLeft: 15, color: 'black' }}
                          onClick={this.selectNumber.bind(this, item)}
                        >
                          {item}
                        </strong>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {rowSeven.map(item => (
                      <td>
                        <div
                          style={
                            selected.find(s => s === item)
                              ? {
                                  zIndex: 12,
                                  marginLeft: 15,
                                  marginTop: 8,
                                  position: 'absolute',
                                  width: 15,
                                  height: 2,
                                  borderBottomheight: 1,
                                  borderBottomColor: 'red',
                                  backgroundColor: 'black',
                                }
                              : undefined
                          }
                        ></div>
                        <strong
                          style={{ marginLeft: 15, color: 'black' }}
                          onClick={this.selectNumber.bind(this, item)}
                        >
                          {item}
                        </strong>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {rowEight.map(item => (
                      <td>
                        <div
                          style={
                            selected.find(s => s === item)
                              ? {
                                  zIndex: 12,
                                  marginLeft: 15,
                                  marginTop: 8,
                                  position: 'absolute',
                                  width: 15,
                                  height: 2,
                                  borderBottomheight: 1,
                                  borderBottomColor: 'red',
                                  backgroundColor: 'black',
                                }
                              : undefined
                          }
                        ></div>
                        <strong
                          style={{ marginLeft: 15, color: 'black' }}
                          onClick={this.selectNumber.bind(this, item)}
                        >
                          {item}
                        </strong>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {rowNine.map(item => (
                      <td>
                        <div
                          style={
                            selected.find(s => s === item)
                              ? {
                                  zIndex: 12,
                                  marginLeft: 15,
                                  marginTop: 8,
                                  position: 'absolute',
                                  width: 15,
                                  height: 2,
                                  borderBottomheight: 1,
                                  borderBottomColor: 'red',
                                  backgroundColor: 'black',
                                }
                              : undefined
                          }
                        ></div>
                        <strong
                          style={{ marginLeft: 15, color: 'black' }}
                          onClick={this.selectNumber.bind(this, item)}
                        >
                          {item}
                        </strong>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {rowTen.map(item => (
                      <td>
                        <div
                          style={
                            selected.find(s => s === item)
                              ? {
                                  zIndex: 12,
                                  marginLeft: 15,
                                  marginTop: 8,
                                  position: 'absolute',
                                  width: 15,
                                  height: 2,
                                  borderBottomheight: 1,
                                  borderBottomColor: 'red',
                                  backgroundColor: 'black',
                                }
                              : undefined
                          }
                        ></div>
                        <strong
                          style={{ marginLeft: 15, color: 'black' }}
                          onClick={this.selectNumber.bind(this, item)}
                        >
                          {item}
                        </strong>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 50 }}>
          <table border="1" width={500}>
            <tbody>
              {games.map(ft => {
                return (
                  <tr style={{ height: 10 }}>
                    <td>{ft.time}</td>
                    <td>{moment(ft.timestamp).format('MM/DD/YYYY')}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <Modal show={this.state.showFinished} onHide={this.hideModal.bind(this)} size="lg">
            <Modal.Header style={{ textAlign: 'center' }} closeButton>
              <div className="modal-logo-container">
                <img src={blueLogo} width={75} className="modal-logo" alt="logo" />
              </div>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>
              <Modal.Title>Congrats you did it!</Modal.Title>
              <p>Your time was {time}</p>
              <Button
                style={{ marginTop: 25 }}
                variant="contained"
                color="secondary"
                className="provide-payment-button"
                onClick={this.hideModal.bind(this)}
              >
                Play Again
              </Button>
            </Modal.Body>
          </Modal>
      </StyledFocus>
    )
  }
}
