import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import LinearProgress from '@material-ui/core/LinearProgress'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import MuiTable from 'mui-virtualized-table'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import './StepBacktest.scss'
import Backtest from '../../lib/backtest'

@inject(({ store: { model } }) => {
  return {
    currentModel: model.currentModel,
    setCurrentModel: model.setCurrentModel,
  }
})
@observer
class StepBacktest extends Component {
  constructor(props) {
    super(props)
    this.iframe = React.createRef()
    this.backtest = new Backtest(this.iframe)
    this.resultFrame = React.createRef()
    this.state = {
      results: [],
      statistics: {},
      running: false,
      progress: 0,
      fromDate: '2020-11-11',
      toDate: '2021-11-05',
      initialCash: 100000,
      exactRange: true,
      commission: 0,
      comparisons: '',
      compSymbols: [],
      showSecurities: false,
    }
    const { currentModel } = this.props
    this.symbols = Array.from(new Set(currentModel.charts.map(c => c.security)))
    this.run.bind(this)
  }

  componentDidMount() {
    this.index = 0
    this.BHSRatings = [] // sparse array
  }

  updateFromDate(a) {
    this.setState({ fromDate: a.target.value })
  }

  updateToDate(a) {
    this.setState({ toDate: a.target.value })
  }

  updateInitialCash(a) {
    this.setState({ initialCash: +a.target.value })
  }

  updateCommission(a) {
    this.setState({ commission: +a.target.value })
  }

  updateExactRange(a) {
    this.setState({ exactRange: a.target.checked })
  }

  updateComparisons(a) {
    this.setState({
      comparisons: a.target.value,
      compSymbols: a.target.value ? a.target.value.toUpperCase().split(/[, ]+/g) : [],
    })
  }

  updateShowSecurities(a) {
    this.setState({ showSecurities: a.target.checked })
  }

  updateUniform(a) {
    this.setState({ uniform: a.target.checked })
  }

  async run() {
    let iframe = this.resultFrame.current.contentWindow || this.resultFrame.current
    iframe.clearChart()
    this.setState({ results: [], statistics: {}, progress: 0, running: true })
    let fromDate = new Date(this.state.fromDate)
    let toDate = new Date(this.state.toDate)
    let fundStrategy = { type: 'threshold' }
    if (this.state.uniform) fundStrategy.type = 'uniform'
    let backtest = await this.backtest.run(
      fundStrategy,
      this.props.currentModel,
      fromDate,
      toDate,
      this.state.initialCash,
      this.state.commission,
      this.state.exactRange,
      progress => this.setState({ progress })
    )
    iframe.loadChart(
      backtest.results,
      this.state.showSecurities ? this.backtest.symbols : [],
      fromDate,
      toDate,
      this.state.compSymbols,
      data => {
        let { results, statistics } = backtest
        const last = results[results.length - 1]
        const first = results[0]
        const years =
          (new Date(last.timestamp) - new Date(first.timestamp)) / 1000 / 60 / 60 / 24 / 365
        statistics.CAGR.comp = {}
        for (let symbol of this.state.compSymbols) {
          let last = data[data.length - 1][symbol]
          let first = data[0][symbol]
          statistics.gross.comp[symbol] = statistics.net.comp[symbol] = {
            percent: last.Close / first.Close - 1,
          }
          statistics.commission.comp[symbol] = statistics.trades.comp[symbol] = { text: 'N/A' }
          statistics.CAGR.comp[symbol] = {
            percent: Math.pow(last.Close / first.Close, 1 / years) - 1,
          }

          statistics.high.comp[symbol] = statistics.drawdown.comp[symbol] = 0
          for (let datum of data) {
            let price = datum[symbol].Close
            if (price > statistics.high.comp[symbol]) statistics.high.comp[symbol] = price
            let drawdown = statistics.high.comp[symbol] - price
            if (drawdown > statistics.drawdown.comp[symbol])
              statistics.drawdown.comp[symbol] = drawdown
          }
          statistics.drawdown.comp[symbol] = {
            dollar: statistics.drawdown.comp[symbol],
            percent: -statistics.drawdown.comp[symbol] / statistics.high.comp[symbol],
          }
          statistics.high.comp[symbol] = { dollar: statistics.high.comp[symbol] }
        }
        this.setState({ results, statistics, running: false })
      }
    )
  }

  render() {
    const updateToDate = this.updateToDate.bind(this)
    const updateFromDate = this.updateFromDate.bind(this)
    const updateInitialCash = this.updateInitialCash.bind(this)
    const updateCommission = this.updateCommission.bind(this)
    const updateComparisons = this.updateComparisons.bind(this)
    const updateShowSecurities = this.updateShowSecurities.bind(this)
    const updateExactRange = this.updateExactRange.bind(this)
    const updateUniform = this.updateUniform.bind(this)
    const {
      results,
      statistics,
      progress,
      fromDate,
      toDate,
      initialCash,
      commission,
      comparisons,
      compSymbols,
      showSecurities,
      uniform,
      running,
      exactRange,
    } = this.state
    return (
      <div>
        <div className="backtest-input-form">
          <TextField name="fromDate" type="date" value={fromDate} onChange={updateFromDate} />
          <TextField name="toDate" type="date" value={toDate} onChange={updateToDate} />
          Exact Dates
          <Checkbox name="securities" checked={exactRange} onChange={updateExactRange} />
          Initial $
          <TextField
            name="initialCash"
            type="number"
            value={initialCash}
            onChange={updateInitialCash}
          />
          Commission $
          <TextField
            name="commission"
            type="number"
            value={commission}
            onChange={updateCommission}
          />
          Comparisons{' '}
          <TextField
            name="comparisons"
            type="text"
            value={comparisons}
            onChange={updateComparisons}
          />
          Strategy Securities{' '}
          <Checkbox name="securities" checked={showSecurities} onChange={updateShowSecurities} />
          Buy &amp; Hold <Checkbox name="uniform" checked={uniform} onChange={updateUniform} />
          <Button
            variant="contained"
            size="small"
            onClick={this.run.bind(this)}
            style={{ float: 'right', top: 6 }}
            disabled={running}
          >
            {running ? 'Running...' : 'Run'}
          </Button>
          <LinearProgress
            variant="determinate"
            color="primary"
            value={progress}
            style={{ marginRight: 0 }}
          />
          <iframe
            ref={this.iframe}
            src="/chartiq/backtest.html"
            title="Backtest Automation"
            style={{ display: 'none' }}
          ></iframe>
        </div>
        <div style={{ width: '100%', height: 'calc(100vh - 467px)' }}>
          <iframe
            ref={this.resultFrame}
            src="/chartiq/results.html"
            title="Backtest Results"
            width="100%"
            height="100%"
          ></iframe>
        </div>
        <Statistics
          symbols={this.symbols}
          compSymbols={compSymbols}
          statistics={statistics}
          results={results}
        />
      </div>
    )
  }
}

export function Statistics(props) {
  const { symbols, compSymbols = [], statistics, results } = props
  const Stat = stat => (
    <span>
      {stat.dollar !== undefined && (
        <span>
          {stat.dollar.toLocaleString(undefined, { style: 'currency', currency: 'USD' })}{' '}
        </span>
      )}
      {stat.percent !== undefined && (
        <span style={{ color: stat.percent >= 0 ? 'green' : 'red' }}>
          {stat.percent >= 0 ? '▲' : '▼'}
          {stat.percent.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 })}
        </span>
      )}
      {stat.text !== undefined && <span className="text">{stat.text}</span>}
    </span>
  )
  const columns = [
    {
      name: 'timestamp',
      header: 'Timestamp',
      width: 120,
      cell: d => {
        let t = new Date(d.time)
        return `${t.getFullYear()}-${t.getMonth() + 1}-${t.getDate()} ${t.getHours() % 12 || 12}:${t
          .getMinutes()
          .toString()
          .padStart(2, '0')}`
      },
    },
    {
      name: 'orders',
      header: 'Orders',
      width: 180,
      cell: d => `${d.side} ${d.shares} of ${d.symbol} @ ${d.price.toFixed(2)}`,
    },
  ]
  let orders = []
  for (let result of results) {
    for (let order of result.orders || []) {
      orders.push({ timestamp: result.timestamp, ...order })
    }
  }

  return (
    <div>
      <div
        style={{
          overflowX: 'scroll',
          display: 'inline-block',
          width: 'calc(100% - 340px)',
          height: '273px',
          marginRight: 20,
        }}
      >
        <Table>
          <TableHead>
            <TableRow
              style={{
                height: 31,
                backgroundColor: '#eeeeee',
                borderBottom: '2px solid rgba(0,0,0,0.12)',
              }}
            >
              <TableCell style={{ padding: 4 }}>Statistic</TableCell>
              <TableCell style={{ padding: 4 }}>Portfolio</TableCell>
              {compSymbols.map(s => (
                <TableCell key={s} style={{ padding: 4 }}>
                  {s} (comp)
                </TableCell>
              ))}
              {symbols.map(s => (
                <TableCell key={s} style={{ padding: 4 }}>
                  {s}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(statistics).map(stat => (
              <TableRow key={stat} style={{ height: 32 }}>
                <TableCell style={{ padding: 4, whiteSpace: 'nowrap' }}>
                  {statistics[stat].name}
                </TableCell>
                <TableCell style={{ padding: 4, whiteSpace: 'nowrap' }}>
                  <Stat {...statistics[stat].portfolio} />
                </TableCell>
                {compSymbols.map(s => (
                  <TableCell key={s} style={{ padding: 4, whiteSpace: 'nowrap' }}>
                    <Stat {...statistics[stat].comp[s]} />
                  </TableCell>
                ))}
                {symbols.map(s => (
                  <TableCell key={s} style={{ padding: 4, whiteSpace: 'nowrap' }}>
                    <Stat {...statistics[stat][s]} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div
        style={{ position: 'relative', display: 'inline-block', width: '320px', height: '273px' }}
      >
        <AutoSizer>
          {({ width, height }) => (
            <MuiTable
              data={orders}
              columns={columns}
              width={320}
              maxHeight={height}
              includeHeaders={true}
              fixedRowCount={1}
              rowHeight={32}
              cellProps={{ style: { padding: '4px' } }}
              isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) =>
                rowData._id && rowData._id === hoveredRowData._id
              }
            />
          )}
        </AutoSizer>
      </div>
    </div>
  )
}
export default StepBacktest
