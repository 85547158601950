import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Router } from '@reach/router'
import Login from '../scenes/Login'
import Callback from '../scenes/Callback'
import FundDetails from '../scenes/Funds/FundDetails'
import SmartFund from '../scenes/SmartFund'
import Marketplace from '../scenes/Marketplace'
import Header from '../common/components/Header'
import Metric from '../scenes/Metric'
import Instruction from '../scenes/Instruction'
import ClientConnect from '../scenes/ClientConnect'
import Admin from '../scenes/Admin'
import Profile from '../scenes/Profile'
import Model from '../scenes/Model'
import ModelBuilder from '../scenes/ModelBuilder'
import Study from '../scenes/Study'
import TotumRisk from '../scenes/TotumRisk'
import AssetManager from '../scenes/AssetManager'
import AssetManagerModel from '../scenes/AssetManager/AssetManagerModel'
import Signal from '../scenes/Signal'
import Focus from '../scenes/Focus'
import ChartMonitor from '../scenes/ChartMonitor'
import UserInsight from '../scenes/UserInsight'
import './App.css'

@inject(({ store: { auth } }) => {
  return {
    authenticated: auth.authenticated,
  }
})
@observer
class App extends Component {
  render() {
    const { authenticated } = this.props
    return (
      <div>
        <Router>
          <Login path="/login" />
          <Callback path="/callback" />
        </Router>
        <div className="row">
          <div className="col-md-12">{authenticated && <Header />}</div>
          <div className="col-md-12">
            <Router>
              <UserInsight path="/" />
              <Focus path="/focus" />
              <SmartFund path="/smartfunds" />
              <Metric path="/metrics" />
              <Marketplace path="/marketplace" />
              <FundDetails path="/fund/:fund" />
              <Instruction path="/instruction" />
              <Signal path="/signals" />
              <ClientConnect path="/clientConnect" />
              <Admin path="/admin/*" />
              <Profile path="/profile" />
              <Model path="/models" />
              <ModelBuilder path="/model/:model/*" />
              <Study path="/studies" />
              <TotumRisk path="/totumRisk" />
              <AssetManager path="/assetManager" />
              <AssetManagerModel path="/assetManager/model/:modelId" />
              <Focus path="/focus" />
              <ChartMonitor path="/chartmonitor" />
              <UserInsight path="/userInsight" />
            </Router>
          </div>
        </div>
      </div>
    )
  }
}

export default App
