import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StyledFormGroup from './styles'

class InputField extends Component {
  state = {
    inputVal: '',
    isFocused: false,
    hasInputValue: false,
  }

  componentDidMount() {
    const { type, initialValue } = this.props
    if (type === 'date') {
      this.setState({ hasInputValue: true })
    }
    if (initialValue) {
      this.setState({ inputVal: initialValue, hasInputValue: true, isFocused: true })
    }
  }

  updateValue = e => {
    const { handleOnChange } = this.props
    let value
    if (!e.target.value) {
      value = ''
    } else {
      value = e.target.value
    }
    this.setState({ inputVal: value }, () => {
      const { inputVal } = this.state
      handleOnChange(inputVal)
    })
  }

  handleOnFocus = () => {
    const { type } = this.props
    const { isFocused } = this.state
    if (type === 'date') {
      return
    }
    this.setState({ isFocused: !isFocused })
  }

  handleOnBlur = () => {
    const { isFocused, inputVal, type } = this.state
    if (type === 'date') {
      return
    }
    if (inputVal === '') {
      return this.setState({ isFocused: !isFocused, hasInputValue: false })
    }

    this.setState({ hasInputValue: true, isFocused: false })
  }

  render() {
    const {
      label,
      customSize,
      type,
      hasError,
      readOnly,
      initialValue,
      maxLength,
      error,
    } = this.props
    const { isFocused, inputVal } = this.state
    return (
      <StyledFormGroup
        className="form-control-group"
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
        hasFocus={isFocused}
        hasInputValue={initialValue ? true : false}
        customSize={customSize}
        hasError={hasError}
      >
        <label
          htmlFor="input_field_name"
          className={hasError ? 'input-field-label has-error' : 'input-field-label'}
        >
          {label}
        </label>
        <div className="input-field-wrapper">
          {!readOnly ? (
            <input
              id="input_field_name"
              type={type}
              value={inputVal}
              className={hasError ? 'input-field has-error' : 'input-field'}
              onChange={str => {
                this.updateValue(str)
              }}
              maxLength={maxLength}
            />
          ) : (
            <input
              id="input_field_name"
              type={type}
              value={initialValue}
              className={hasError ? 'input-field has-error' : 'input-field'}
              readonly
            />
          )}
        </div>
        {hasError && <p className="field-error">{error ? error : 'This field is required.'}</p>}
      </StyledFormGroup>
    )
  }
}

InputField.defaultProps = {
  customSize: null,
  isRequired: null,
  hasError: false,
  initialValue: '',
}

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  customSize: PropTypes.string,
  isRequired: PropTypes.bool,
  handleOnChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  initialValue: PropTypes.string,
}

export default InputField
