import styled from 'styled-components'

export const StyledFocus = styled.div`
    @media screen and (min-width: 1201px) {
        margin-left: 60em;
        margin-top: 5em;
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        margin-left: 7em;
        margin-top: 5em;
    }

  @media screen and (min-width: 769px) and (max-width: 1024px){
    margin-left: 18em;
    margin-top: 5em;
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    margin-left: 10em;
    margin-top: 5em;
    width: 25%
  }

  @media screen and (min-width: 320px) and (max-width: 480px) {
    width: 10em
  }

`
