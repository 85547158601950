import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import InputField from '../../common/components/InputField'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

@inject(({ store: { model } }) => {
  return {
    currentModel: model.currentModel,
    setCurrentModel: model.setCurrentModel,
  }
})
@observer
class StepDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formErrors: [],
    }
  }
  componentDidMount() {
    const { strategy, formErrors } = this.props
    this.setState({ strategy, formErrors })
  }

  render() {
    const { currentModel, setCurrentModel } = this.props
    return (
      <div>
        <div className="row">
          <div className="col-md-12"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Strategy</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ width: '100%' }}>
                  <div className="row">
                    <div className="col-md-12">
                      <InputField
                        type="text"
                        label="Name (required)"
                        customSize="70%"
                        handleOnChange={str => setCurrentModel({ strategy: str })}
                        isRequired
                        initialValue={currentModel.strategy}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <InputField
                        type="text"
                        label="Description"
                        customSize="70%"
                        handleOnChange={str => setCurrentModel({ description: str })}
                        initialValue={currentModel.description}
                      />
                    </div>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Fund Model Exposure</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ width: '100%' }}>
                  <div className="row">
                    <div className="col-md-4">
                    <InputField
                        type="text"
                        label="Symbol Allocation"
                        customSize="100%"
                        handleOnChange={str => setCurrentModel({ allocation: str })}
                        initialValue={currentModel.allocation}
                      />
                    </div>
                    {/* <div className="col-md-4">
                      <InputLabel htmlFor="age-native-simple">Min Account Size</InputLabel>
                      <Select
                        value={currentModel.minAccountSize}
                        fullWidth={true}
                        onChange={event => setCurrentModel({ minAccountSize: event.target.value })}
                      >
                        {minAccountSizeValues.map((v, idx) => (
                          <MenuItem key={idx} value={idx + 1}>
                            {v}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="col-md-4">
                    <InputLabel htmlFor="age-native-simple">Max Allocation</InputLabel>
                      <Select
                        value={currentModel.maxAllocation}
                        fullWidth={true}
                        onChange={event => setCurrentModel({ maxAllocation: event.target.value })}
                      >
                        {maxAllocationValues.map((v, idx) => (
                          <MenuItem key={idx} value={idx + 1}>
                            {v}
                          </MenuItem>
                        ))}
                      </Select>
                    </div> */}
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    )
  }
}

export default StepDetail
