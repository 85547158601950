import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Table from '../../common/components/Table'
import { Card } from '@material-ui/core'
import Widget from '../../common/components/Widget'

@inject(({ store: { auth, app, ui } }) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    getAllBuySignals: app.signalStore.getAllBuySignals,
    getAllSellSignals: app.signalStore.getAllSellSignals,
    buySignals: app.signalStore.buySignals,
    sellSignals: app.signalStore.sellSignals,
    profile: auth.profile,
    averageReturn: app.signalStore.averageReturn,
    best: app.signalStore.best,
    worst: app.signalStore.worst,
  }
})
@observer
export default class Signal extends Component {
  state = {}

  componentDidMount = async () => {
    window.document.title = 'Bob | Trade Signals'
    const { getAllBuySignals, getAllSellSignals, isAuthenticated } = this.props
    await isAuthenticated()
    await getAllBuySignals()
    await getAllSellSignals()
  }

  render = () => {
    const {
      buySignals,
      sellSignals,
      averageReturn,
      best,
      worst,
      profile: { token },
    } = this.props
    const clientTableOpts = {
      rowsPerPage: 100,
      list: buySignals.concat(sellSignals),
      listColumns: this.listColumns(token),
    }
    return (
      <div className="row" style={{ width: '85%' }}>
        <div className="col-md-12">
          <Card style={{ marginLeft: '5em' }}>
            <div className="row" style={{ marginLeft: '5em' }}>
              <h2>Average Return : {averageReturn}</h2>
            </div>
            <div className="row" style={{ marginLeft: '5em' }}>
              <h2>Best Return : {best}</h2>
            </div>
            <div className="row" style={{ marginLeft: '5em' }}>
              <h2>Worst Return : {worst}</h2>
            </div>
          </Card>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Card>
              <Widget {...clientTableOpts} classProps={{ columnSize: 12 }} body={Table} />
            </Card>
          </div>
        </div>
      </div>
    )
  }
  listColumns(token) {
    return [
      {
        displayName: 'Chart',
        propPath: '/',
        displayFn: item => {
          const chartId = item.chartId
          window.setupDate = item.setupDate
          return (
            <div>
              <p
                onClick={() =>
                  (window.location.href = `chartiq/app.html#public=true&chartId=${chartId}&token=${token}&setupDate=${item.setupDate}`)
                }
              >{`${chartId}`}</p>
            </div>
          )
        },
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Ticker',
        propPath: 'id',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Market Cap',
        propPath: 'marketCap',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Model',
        propPath: 'strategy',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Price',
        propPath: 'entryPrice',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Current Price',
        propPath: 'currentPrice',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Allocation',
        propPath: 'scale',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Signal Type',
        propPath: 'side',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Signal Date',
        propPath: 'signalDate',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Setup Date',
        propPath: 'setupDate',
        filterToolTip: true,
        filter: false,
      },
    ]
  }
}
