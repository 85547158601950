import styled from 'styled-components'
import { colors, typography, patterns } from '../../../assets/styles'

const StyledUserProfileMenu = styled.ul`
  ${patterns.dropdownMenuBackground};
  .has-divider {
    border-top: 1px solid #ebebeb;
    margin-top: 0.35rem;
    padding-top: 0.35rem;
  }
  .dropdown-link {
    display: block;
    width: auto;
    padding: 0.5rem 1rem;
    color: ${colors.textGrey};
    font-size: ${typography.baseFontSize};
    transition: all 200ms linear;
    text-decoration: none;
    &:hover,
    &:focus {
      background: #f3f3f3;
    }
  }
  .dropdown-button {
    ${patterns.buttonDefault};
    width: 100%;
    padding: 0.5rem 1rem;
    text-align: right;
    outline: none;

    &:hover,
    &:focus {
      background: #f3f3f3;
    }
    svg {
      margin-left: 0.6rem;
    }
    &.log-out-button {
      color: ${colors.bobBlue};
    }
    &.impersonation-button {
      color: ${colors.textGrey};
      img {
        margin-left: 0.5rem;
      }
    }
    &.end-impersonation-button {
      ${patterns.buttonDefault};
      color: ${colors.bobOrange};
    }
  }
  .impersonation-menu {
    list-style: none;
    background: #ebebeb;
    padding: 0;
    max-height: 140px;
    overflow: auto;
    .impersonation-user {
      ${patterns.buttonDefault};
      background: transparent;
      width: 100%;
      padding: 0.5rem 1rem;
      text-align: right;
      outline: none;
      font-size: ${typography.smallBaseFontSize};
      &:hover,
      &:focus {
        background: #f3f3f3;
      }
    }
  }
`
export default StyledUserProfileMenu
