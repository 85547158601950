import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
import searchIcon from '../../../assets/img/search.png'
import closeIcon from '../../../assets/img/plus-sign.png'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import PictureAsPdf from '@material-ui/icons/PictureAsPdf'
import Link from '@material-ui/core/Link'
import PortfolioScore from '../../../scenes/TotumRisk/component/portfolioScore'
import currencyFilter from '../../../lib/currency'
import blueLogo from '../../../assets/img/bob-logo-modal.png'

import op from 'simple-object-path'

import StyledMarketPlaceList from './styles'
import { Modal } from 'react-bootstrap'
import { Select, MenuItem, FormHelperText } from '@material-ui/core'

@inject(({ store: { navigate, fund, auth, totumRisk, user } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    getUserSmartFunds: user.smartFundStore.getUserSmartFunds,
    getModelById: fund.marketplaceStore.getModelById,
    getPublicSmartFunds: fund.marketplaceStore.getPublicSmartFunds,
    copyFundToUser: fund.marketplaceStore.copyFundToUser,
    fundsUserHasMirrored: fund.marketplaceStore.fundsUserHasMirrored,
    searchFilter: fund.marketplaceStore.searchFilter,
    fundList: fund.marketplaceStore.fundList,
    filters: fund.marketplaceStore.filters,
    filteredList: fund.marketplaceStore.filteredList,
    managerList: fund.marketplaceStore.managerList,
    assetTypes: fund.marketplaceStore.assetTypes,
    assetManagers: fund.marketplaceStore.assetManagers,
    getAssetManagers: fund.marketplaceStore.getAssetManagers,
    factSheets: fund.marketplaceStore.factSheets,
    setSelectedModel: fund.marketplaceStore.setSelectedModel,
    setSelectedFund: fund.marketplaceStore.setSelectedFund,
    selectedFund: fund.marketplaceStore.selectedFund,
    selectedModel: fund.marketplaceStore.selectedModel,
    addFundToUser: fund.marketplaceStore.addFundToUser,
    filterByName: fund.marketplaceStore.filterByName,
    removeFromFilters: fund.marketplaceStore.removeFromFilters,
    getUserSmartFundMatches: fund.marketplaceStore.getUserSmartFundMatches,
    calculateFundScores: fund.marketplaceStore.calculateFundScores,
    currentFundUserProfile: fund.currentFundUserProfile,
    setCurrentFund: fund.setCurrentFund,
    getFundUserProfile: fund.getFundUserProfile,
    evaluateClientForFund: user.evaluateClientForFund,
    isRetail: user.isRetail,
    retailProfile: user.retailProfile,
    setFundUserProfile: fund.setFundUserProfile,
  }
})
@observer
class MarketplaceList extends Component {
  state = {
    isLoading: true,
    inUserCollection: false,
  }

  async componentDidMount() {
    const {
      getPublicSmartFunds,
      getAssetManagers,
      fundsUserHasMirrored,
      isAuthenticated,
      setSelectedFund,
      setSelectedModel,
      calculateFundScores,
      getUserSmartFunds,
    } = this.props
    await isAuthenticated()
    setSelectedFund(undefined)
    setSelectedModel(undefined)

    await getPublicSmartFunds()
    await fundsUserHasMirrored()
    await getAssetManagers()
    await calculateFundScores()

    const selectedFunds = await getUserSmartFunds()
    this.setState({
      selectedFunds,
      isLoading: false,
    })
  }

  getTypeColor(fundType) {
    let color
    switch (fundType) {
      case 'mixed':
        color = '#BE5BBF'
        break
      case 'etf':
        color = '#E58124'
        break
      default:
        color = '#000000'
    }
    return color
  }

  getAvatarColor(fundType) {
    let color
    switch (fundType) {
      case 'mixed':
        color = '#512D44'
        break
      case 'etf':
        color = '#ED5A36'
        break
      default:
        color = '#000000'
    }
    return color
  }

  closeFundDetail() {
    const { setSelectedFund, setSelectedModel, selectedModel } = this.props
    setSelectedFund(undefined)
    if (selectedModel) setSelectedModel(undefined)
  }

  closeModelDetail() {
    const { setSelectedModel } = this.props
    setSelectedModel(undefined)
  }

  hasFundSelectionFailure(fund, responseType) {
    const { navigate, retailProfile = {} } = this.props
    const {
      paymentLinked = false,
      brokerageLinked = false,
      riskScoreComplete = false,
    } = retailProfile
    const cash = op(retailProfile, 'cash') || 0
    const buyIn = fund.buyIn || 0
    const { selectedFund } = fund
    if (selectedFund) {
      if (responseType === 'button') {
        return (
          <Button
            variant="contained"
            color="secondary"
            className="provide-payment-button"
            onClick={() => navigate(`/fund/${fund._id}`)}
          >
            Go To Fund
          </Button>
        )
      } else {
        return 'This is your selected fund.'
      }
    }
    if (!riskScoreComplete) {
      if (responseType === 'button') {
        return (
          <Button
            variant="contained"
            color="secondary"
            className="provide-payment-button"
            onClick={() => navigate('/totumRisk')}
          >
            Complete Questionnaire
          </Button>
        )
      } else {
        return 'Please complete the Totum Risk questionnaire before selecting a fund'
      }
    }
    if (!brokerageLinked) {
      if (responseType === 'button') {
        return (
          <Button
            variant="contained"
            color="secondary"
            className="provide-payment-button"
            onClick={() => navigate('/profile')}
          >
            Link Brokerage Account
          </Button>
        )
      } else {
        return 'Please link your bokerage account before selecting a fund'
      }
    }
    if (cash === 0) {
      if (responseType === 'button') {
        return (
          <Button
            variant="contained"
            color="secondary"
            className="provide-payment-button"
            onClick={() => navigate('/profile')}
          >
            Re-Link Brokerage
          </Button>
        )
      } else {
        return 'Your account has zero dollars ($0). You may need to relink your brokerage account.'
      }
    }
    if (!paymentLinked) {
      if (responseType === 'button') {
        return (
          <Button
            variant="contained"
            color="secondary"
            className="provide-payment-button"
            onClick={() => navigate('/profile')}
          >
            Add Payment Information
          </Button>
        )
      } else {
        return 'Please link your payment information before selecting a fund'
      }
    }
    if (buyIn > cash) {
      if (responseType === 'button') {
        return true
      } else {
        return `Your retail account ( ${currencyFilter(
          cash
        )} ) does not meet the buy in requirements for this fund.`
      }
    }

    return false
  }

  async initAddFundToUser() {
    const { getFundUserProfile, selectedFund, currentFundUserProfile } = this.props
    const { accountAllocation = 1 } = currentFundUserProfile || {}
    this.setState({ showAddToCollection: true, loadingFundProfile: true })
    await getFundUserProfile(selectedFund._id)
    await this.changeFundAllocation({ target: { value: accountAllocation } })
    this.setState({ loadingFundProfile: false })
  }

  async changeFundAllocation(event) {
    const {
      currentFundUserProfile,
      evaluateClientForFund,
      selectedFund,
      setFundUserProfile,
    } = this.props
    const clone = Object.assign({}, currentFundUserProfile, {
      accountAllocation: event.target.value,
    })
    const fundParticipation = await evaluateClientForFund(clone, selectedFund)
    console.log('fundParticipation', fundParticipation)
    setFundUserProfile(clone)
    this.setState({ fundParticipation })
  }

  async publishFund() {
    const {
      setCurrentFund,
      selectedFund,
      addFundToUser,
      currentFundUserProfile,
      navigate,
    } = this.props
    console.log('selectedFund', selectedFund)
    setCurrentFund(selectedFund)

    this.setState({ loadingFundProfile: true })
    await addFundToUser(currentFundUserProfile)
    this.setState({ showAddToCollection: false, loadingFundProfile: false })
    navigate('/smartfunds')
  }
  render() {
    const {
      isLoading,
      showAddToCollection,
      loadingFundProfile,
      fundParticipation,
      selectedFunds = [],
    } = this.state

    const {
      assetManagers,
      selectedFund,
      selectedModel,
      assetTypes,
      fundList,
      filters,
      filteredList,
      searchFilter,
      setSelectedFund,
      setSelectedModel,
      filterByName,
      removeFromFilters,
      factSheets,
      isRetail,
      currentFundUserProfile = {},
    } = this.props
    let masterList =
      filteredList && filteredList.length > 0 ? filteredList.filter(l => !l.featureFund) : []
    console.log('selectedFunds', selectedFunds)
    const featureFund = fundList.find(f => f.featureFund === true)
    const smartFundCount = masterList.length
    const { assetManager = {} } = selectedModel ? selectedModel : {}
    const fundHasFees = fund => (fund.strategies || []).some(x => x.fee && Number(x.fee) > 0)
    return (
      <StyledMarketPlaceList>
        <Box component="section">
          {isLoading ? (
            <LoadingIndicator loadingText="Loading the Marketplace" />
          ) : (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={2.5}>
                <Card className="filter-container">
                  <CardContent>
                    <p className="filter-label">Filter by</p>

                    <h5>ASSET TYPE</h5>
                    {assetTypes.map((assetType, index) => {
                      return (
                        <span className="filter-asset-manager-name" key={index}>
                          <span className="filter-link" onClick={filterByName}>
                            {assetType}
                          </span>
                          <br />
                        </span>
                      )
                    })}

                    <h5>MANAGER</h5>
                    {assetManagers.map((manager, index) => {
                      return (
                        <span className="filter-asset-manager-name" key={index}>
                          <span className="filter-link" onClick={filterByName}>
                            {manager.name}
                          </span>
                          <br />
                        </span>
                      )
                    })}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={5}>
                {selectedFunds.length === 0 && featureFund && (
                  <div>
                    <Typography className="recommended-by-bob" variant="caption" component="p">
                      Recommended by Bob
                    </Typography>
                    <Card
                      className="fund-card"
                      fund={featureFund}
                      onClick={() => setSelectedFund(featureFund)}
                    >
                      <CardContent>
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={1} className="fund-card-avatar">
                            <Avatar
                              style={{ backgroundColor: this.getAvatarColor(featureFund.type) }}
                            >
                              {featureFund.name
                                .split(' ')
                                .map(word => word.charAt(0))
                                .join('')
                                .toUpperCase()}
                            </Avatar>
                          </Grid>
                          <Grid item xs={9} className="fund-card-label">
                            <Typography variant="h5" component="h5">
                              {featureFund.name}
                            </Typography>
                            <Typography className="asset-range" variant="caption" component="p">
                              {featureFund.assetRange}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} className="fund-card-type">
                            <Chip
                              className="type-chip"
                              style={{ backgroundColor: this.getTypeColor(featureFund.type) }}
                              label={featureFund.type.toUpperCase()}
                            />
                            {featureFund.alreadyMirrored && (
                              <Typography className="asset-range" variant="caption" component="p">
                                Mirrored
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        <Grid className="model-row" container direction="row" spacing={2}>
                          {featureFund.strategies.map((model, modelIndex) => {
                            return (
                              <Chip className="model-chip" label={model.name} key={modelIndex} />
                            )
                          })}
                        </Grid>
                      </CardContent>
                    </Card>
                    <div className="feature-line-divider"></div>
                  </div>
                )}
                <div className="search-box">
                  <img className="search-box-icon" src={searchIcon} alt="Search Icon" />
                  <div className="search-box-divider"></div>
                  <input
                    className="search-box-content"
                    placeholder="Search by Fund Name, Asset Type, Model Name…"
                    onChange={searchFilter.bind(this)}
                  />
                </div>
                {filters.length > 0 && (
                  <Grid className="filter-box" container direction="row" spacing={3}>
                    <Grid>
                      <Typography className="filter-label" variant="caption" component="p">
                        Filters:
                      </Typography>
                    </Grid>
                    <Grid>
                      {filters.map(f => {
                        return (
                          <Chip
                            className="filer-chip-content"
                            label={f}
                            onDelete={removeFromFilters.bind(null, f)}
                          ></Chip>
                        )
                      })}
                    </Grid>
                  </Grid>
                )}
                <Typography className="number-smartfunds-message" variant="caption" component="p">
                  {`Showing all ${smartFundCount} Smart Funds`}
                </Typography>
                <Grid className="fund-list-container">
                  {(selectedFunds.length === 0
                    ? (masterList || []).map(s => Object.assign({}, s, { masterList: true }))
                    : selectedFunds.map(s => Object.assign({}, s, { selectedFund: true }))
                  ).map((fund, index) => {
                    return (
                      <Card
                        key={index}
                        className="fund-card"
                        fund={fund}
                        onClick={() => setSelectedFund(fund)}
                      >
                        <CardContent>
                          <Grid container direction="row" spacing={2}>
                            <Grid item xs={1} className="fund-card-avatar">
                              <Avatar style={{ backgroundColor: this.getAvatarColor(fund.type) }}>
                                {fund.name
                                  .split(' ')
                                  .map(word => word.charAt(0))
                                  .join('')
                                  .toUpperCase()}
                              </Avatar>
                            </Grid>
                            <Grid item xs={9} className="fund-card-label">
                              <Typography variant="h5" component="h5">
                                {fund.name}
                              </Typography>
                              <Typography className="asset-range" variant="caption" component="p">
                                {fund.assetRange}
                              </Typography>
                            </Grid>
                            <Grid item xs={1} className="fund-card-type">
                              <Chip
                                className="type-chip"
                                style={{ backgroundColor: this.getTypeColor(fund.type) }}
                                label={(op(fund, 'type') || '').toUpperCase()}
                              />
                              {fund.alreadyMirrored && (
                                <Typography className="asset-range" variant="caption" component="p">
                                  Mirrored
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          <Grid className="model-row" container direction="row" spacing={2}>
                            {fund.strategies.map((model, modelIndex) => {
                              return (
                                <Chip className="model-chip" label={model.name} key={modelIndex} />
                              )
                            })}
                          </Grid>
                          <Grid container direction="row">
                            {isRetail && fund.scoreInfo && fund.scoreInfo.score && (
                              <Grid className="fund-score">
                                <PortfolioScore
                                  smallScore={true}
                                  portfolioScore={fund.scoreInfo ? fund.scoreInfo.score : 0}
                                />
                              </Grid>
                            )}
                            <Grid
                              className={
                                isRetail ? 'fund-buy-in-wrapper-with-score' : 'fund-buy-in-wrapper'
                              }
                            >
                              <Typography className="fund-buy-in">
                                FUND MINIMUM BUY IN AMOUNT
                              </Typography>
                              <Typography className="fund-buy-in-amount">
                                {currencyFilter(fund.buyIn)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    )
                  })}
                </Grid>
              </Grid>
              <Grid item xs={4} className="detail-wrapper">
                {selectedFund && (
                  <Card
                    className={
                      selectedModel
                        ? 'model-detail-overlapped fund-detail-card'
                        : 'fund-detail-card'
                    }
                  >
                    <Grid container direction="column" spacing={2}>
                      <Grid>
                        <img
                          className="detail-close-sign"
                          src={closeIcon}
                          alt="Close Icon"
                          onClick={this.closeFundDetail.bind(this)}
                        />
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        direction="column"
                        justify="center"
                        className="fund-detail-header"
                      >
                        <Avatar style={{ backgroundColor: this.getAvatarColor(selectedFund.type) }}>
                          {selectedFund.name
                            .split(' ')
                            .map(word => word.charAt(0))
                            .join('')
                            .toUpperCase()}
                        </Avatar>
                        <Typography
                          className="fund-detail-name"
                          align="center"
                          variant="h5"
                          component="h5"
                        >
                          {selectedFund.name}
                        </Typography>
                        {this.hasFundSelectionFailure(selectedFund, 'button') ? (
                          this.hasFundSelectionFailure(selectedFund, 'button')
                        ) : !selectedFund.alreadyMirrored ? (
                          <Button
                            variant="contained"
                            color="primary"
                            className="add-to-collection-button"
                            onClick={this.initAddFundToUser.bind(this)}
                          >
                            Join Fund
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            className="added-to-collection-button"
                          >
                            Joined!
                          </Button>
                        )}
                      </Grid>
                      {this.hasFundSelectionFailure(selectedFund, 'label') && (
                        <Grid item className="fund-detail-type">
                          <label className="detail-header">
                            {this.hasFundSelectionFailure(selectedFund, 'label')}
                          </label>
                          <br />
                        </Grid>
                      )}
                      <Grid item className="fund-detail-type">
                        <label className="detail-header">Type</label>
                        <br />
                        <Chip
                          style={{ backgroundColor: this.getTypeColor(selectedFund.type) }}
                          className="type-chip"
                          label={(op(selectedFund, 'type') || '').toUpperCase()}
                        />
                      </Grid>
                      <Grid item className="fund-detail-size">
                        <label className="detail-header">FUND MINIMUM BUY IN AMOUNT</label>
                        <Typography variant="body2" component="p">
                          {currencyFilter(selectedFund.buyIn)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className={`fund-detail-models${
                          fundHasFees(selectedFund) ? ' hasFees' : ''
                        }`}
                      >
                        <label className="detail-header">Models</label>
                        <ul className="model-list">
                          {selectedFund.strategies.map((model, modelIndex) => {
                            return (
                              <li key={modelIndex}>
                                <Chip
                                  className="model-chip"
                                  label={model.name}
                                  onClick={() => setSelectedModel(model)}
                                />
                              </li>
                            )
                          })}
                        </ul>
                      </Grid>
                      {fundHasFees(selectedFund) && (
                        <span className="model-fee-disclaimer">
                          This fund includes models which have additional fees
                        </span>
                      )}
                    </Grid>
                  </Card>
                )}
                {selectedModel && (
                  <Card className="model-detail-card">
                    <Grid container direction="column" spacing={2}>
                      <Grid>
                        <img
                          className="detail-close-sign"
                          src={closeIcon}
                          alt="Close Icon"
                          onClick={this.closeModelDetail.bind(this)}
                        />
                      </Grid>
                      <Grid container alignItems="center" direction="column" justify="center">
                        <Typography
                          className="model-detail-fund-name"
                          align="center"
                          variant="h6"
                          component="h6"
                        >
                          {selectedFund.name}
                        </Typography>
                        <Typography
                          className="model-detail-name"
                          align="center"
                          variant="h5"
                          component="h5"
                        >
                          {selectedModel.name}
                        </Typography>
                        {assetManager && (
                          <Grid className="model-logo">
                            <img
                              src={assetManager.logoUrl}
                              style={{ width: '7em', height: '7em' }}
                              alt="Model Logo"
                            />
                          </Grid>
                        )}
                        {factSheets && (
                          <Link
                            href={
                              factSheets.find(
                                c => String(selectedModel._id) === String(c.strategyId)
                              )
                                ? factSheets.find(
                                    c => String(selectedModel._id) === String(c.strategyId)
                                  ).institutional
                                : ''
                            }
                            target="_blank"
                          >
                            <PictureAsPdf /> View FactSheet
                          </Link>
                        )}
                      </Grid>
                      <Grid item className="model-detail-description">
                        <Typography variant="body2" component="p">
                          {selectedModel.description}
                        </Typography>
                      </Grid>
                      <Grid item className="model-detail-securities">
                        <label className="detail-header">Holdings</label>
                        <br />
                        {selectedModel.Tickers.map((security, key) => {
                          return (
                            <Chip
                              variant="outlined"
                              key={key}
                              className="security-chip"
                              label={security.symbol}
                            />
                          )
                        })}
                      </Grid>
                      {/* <Grid item className="model-detail-allocations">
                        <label className="detail-header">Allocations</label>
                        <ul className="allocations-list">
                        {selectedModel.Tickers.map((allocation, key) => {
                          return (
                            <li key={key}>
                              <Typography variant="body2" component="p">
                                {allocation}
                              </Typography>
                            </li>
                          )
                        })}
                      </ul>
                      </Grid> */}
                      <Grid item className="model-detail-sectors">
                        <label className="detail-header">Sectors</label>
                        <ul className="sector-list">
                          {selectedModel.sectors.map((sector, key) => {
                            return (
                              <li key={key}>
                                <Chip className="sector-chip" variant="outlined" label={sector} />
                              </li>
                            )
                          })}
                        </ul>
                      </Grid>
                      <Grid item className="model-fee">
                        <label className="detail-header">Model Fee</label>
                        <Typography variant="body2" component="p">
                          {selectedModel.fee && Number(selectedModel.fee) > 0
                            ? selectedModel.fee * 100 + '%'
                            : 'FREE'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                )}
              </Grid>
              <div>
                <Modal
                  show={showAddToCollection || false}
                  onHide={() => this.setState({ showAddToCollection: false })}
                  size="lg"
                >
                  <Modal.Header style={{ textAlign: 'center' }} closeButton>
                    <div className="modal-logo-container">
                      <img src={blueLogo} width={75} className="modal-logo" alt="logo" />
                    </div>
                  </Modal.Header>
                  <Modal.Body style={{ textAlign: 'center' }}>
                    <Modal.Title>You're Almost Done{fundParticipation}</Modal.Title>
                    {loadingFundProfile && (
                      <LoadingIndicator loadingText="Loading the Marketplace" />
                    )}
                    {!loadingFundProfile && (
                      <Grid container direction="column" spacing={2} style={{ padding: 20 }}>
                        <Grid>
                          <div>Account Total:</div>
                          <h4>{currencyFilter(currentFundUserProfile.tdaCash)}</h4>
                          <hr></hr>

                          <FormHelperText>
                            How much of the {currencyFilter(currentFundUserProfile.tdaCash)} would
                            you like invest?
                          </FormHelperText>
                          <Select
                            style={{ display: 'flex' }}
                            value={(currentFundUserProfile || {}).accountAllocation || 1}
                            onChange={this.changeFundAllocation.bind(this)}
                          >
                            <MenuItem value={0}>
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={0.1}>10%</MenuItem>
                            <MenuItem value={0.2}>20%</MenuItem>
                            <MenuItem value={0.3}>30%</MenuItem>
                            <MenuItem value={0.4}>40%</MenuItem>
                            <MenuItem value={0.5}>50%</MenuItem>
                            <MenuItem value={0.6}>60%</MenuItem>
                            <MenuItem value={0.7}>70%</MenuItem>
                            <MenuItem value={0.8}>80%</MenuItem>
                            <MenuItem value={0.9}>90%</MenuItem>
                            <MenuItem value={1.0}>100%</MenuItem>
                          </Select>
                          {(currentFundUserProfile || {}).bobBuyingPower <
                            (selectedFund || {}).buyIn && (
                            <FormHelperText style={{ color: 'red' }}>
                              Fund Buy In: {currencyFilter((selectedFund || {}).buyIn)}. You no
                              longer meet fund buy in requirements.
                            </FormHelperText>
                          )}
                          {!(
                            (currentFundUserProfile || {}).bobBuyingPower <
                            (selectedFund || {}).buyIn
                          ) && (
                            <div>
                              <br />
                              <div>
                                <strong>Investment Amount</strong>
                              </div>
                              <div>
                                <small>This amount will be invested</small>
                              </div>
                              <h2>{currencyFilter(currentFundUserProfile.bobBuyingPower)}</h2>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    {(currentFundUserProfile || {}).bobBuyingPower > (selectedFund || {}).buyIn && (
                      <button className="modal-button" onClick={this.publishFund.bind(this)}>
                        Start Trading!
                      </button>
                    )}
                  </Modal.Body>
                </Modal>
              </div>
            </Grid>
          )}
        </Box>
      </StyledMarketPlaceList>
    )
  }
}

export default MarketplaceList
